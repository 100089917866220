import React from 'react'

import { IReasonOptionComponentProps } from './types'

import * as S from './styles'

const ReasonOptionComponent: React.FC<IReasonOptionComponentProps> = ({
  id,
  label,
  handleChange,
  selectedReasonsId,
  ...inputProps
}) => {
  const thisReasonIsChecked = (id: string) => {
    return selectedReasonsId.includes(id)
  }

  return (
    <S.ReasonLabelWrapper htmlFor={id}>
      <S.ReasonInputWrapper thisReasonIsChecked={thisReasonIsChecked(id)}>
        <S.ReasonInput
          id={id}
          value={id}
          type="checkbox"
          onChange={handleChange}
          checked={thisReasonIsChecked(id)}
          {...inputProps}
        />
      </S.ReasonInputWrapper>
      <S.ReasonText>{label}</S.ReasonText>
    </S.ReasonLabelWrapper>
  )
}

export default ReasonOptionComponent
