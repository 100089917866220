import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Container } from '../../layout'
import { formatPrice } from '../../utils'
import { ICompletePurchase, IShopperBuyProductsDetails } from '../../interfaces'
import { routesNameEnum } from '../../routes/routesNameEnum'

import * as S from './styles'
import { getPurchase } from '../../services'
import { tokenAccessContext } from '../../contexts/TokenAccess/tokenAccessContext'
import { useLoading } from '../../hooks'
import { LoadingComponent } from '../../components'

const CompletePurchaseDetailTemplate: React.FC = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const { token } = useContext(tokenAccessContext)
  const { isLoading, startLoading, stopLoading } = useLoading()
  const [purchaseDetails, setPurchaseDetails] = useState<
    IShopperBuyProductsDetails[]
  >([])
  const purchase = (state as { purchase: ICompletePurchase }).purchase
  const handleGoBack = () => navigate(routesNameEnum.COMPLETE_PURCHASES)

  const getPurchaseDetails = () => {
    ;(async () => {
      try {
        startLoading()
        const response = await getPurchase(token.accessToken, purchase.order_id)
        setPurchaseDetails(response.data.shopperBuyProducts)
      } catch (error) {
        console.log(error)
        setPurchaseDetails([])
      } finally {
        stopLoading()
      }
    })()
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getPurchaseDetails(), [])

  return (
    <Container>
      <S.CompletePurchaseDetailTemplateWrapper>
        <S.GoBackButtonWrapperAndTitle>
          <S.GoBackButton onClick={handleGoBack} />
          <S.ScreenTitle>Peças</S.ScreenTitle>
        </S.GoBackButtonWrapperAndTitle>
        <S.PurchaseInfos style={{ marginBottom: '32px' }}>
          <S.InfoLabel style={{ marginBottom: '8px' }}>
            Compra: {purchase.order_id}
          </S.InfoLabel>
          <S.InfoLabel>Cliente: {purchase.name}</S.InfoLabel>
        </S.PurchaseInfos>
        <S.ItemsWrapper>
          {isLoading ? (
            <LoadingComponent />
          ) : purchaseDetails.length > 0 ? (
            purchaseDetails.map(
              (product: Partial<IShopperBuyProductsDetails>, index: number) => (
                <S.Item key={index}>
                  <S.InfoLabel style={{ marginBottom: '32px' }}>
                    {product.name}
                  </S.InfoLabel>
                  <S.InfoPriceLabel>
                    Valor Pago: {formatPrice(Number(product.price_buy))}
                  </S.InfoPriceLabel>
                  <S.InfoPriceLabel>
                    Valor a Receber: {formatPrice(Number(product.price_sell))}
                  </S.InfoPriceLabel>
                </S.Item>
              )
            )
          ) : (
            <S.InfoLabel>Produto não disponível</S.InfoLabel>
          )}
        </S.ItemsWrapper>
      </S.CompletePurchaseDetailTemplateWrapper>
    </Container>
  )
}

export default CompletePurchaseDetailTemplate
