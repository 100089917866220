import styled from 'styled-components'
import { themeColors } from '../../styles/theme.colors'

export const ToggleWrapper = styled.div`
  width: 100%;
  display: flex;
  min-width: 300px;
  border-radius: 45px;
  justify-content: space-between;
  border: 1px solid ${themeColors.primary_color};

  .label {
    width: 50%;
    padding: 14px;
    display: flex;
    font-size: 16px;
    align-items: center;
    justify-content: center;
    font-family: 'Brandon Regular';

    > input {
      display: none;
    }
  }

  .label-type-one {
    border-top-left-radius: 45px;
    border-bottom-left-radius: 45px;
    background-color: ${themeColors.white};
    color: ${themeColors.secondary_color};
    border-right: 1px solid ${themeColors.secondary_color};

    &.selected-option {
      background-color: ${themeColors.secondary_color};
      color: ${themeColors.white};
    }
  }

  .label-type-two {
    border-top-right-radius: 45px;
    border-bottom-right-radius: 45px;
    border-left: 1px solid ${themeColors.secondary_color};
    background-color: ${themeColors.white};
    color: ${themeColors.secondary_color};

    &.selected-option {
      background-color: ${themeColors.secondary_color};
      color: ${themeColors.white};
    }
  }
`
