import React from 'react'

import { ButtonStyleTypeEnum, IButtonComponentProps } from './types'

import * as S from './styles'

const ButtonComponent: React.FC<IButtonComponentProps> = ({
  children,
  formatType = 'DEFAULT',
  styleType = ButtonStyleTypeEnum.PRIMARY,
  ...rest
}) => {
  return (
    <S.ButtonWrapper formatType={formatType} {...rest} styleType={styleType}>
      {children}
    </S.ButtonWrapper>
  )
}

export default ButtonComponent
