import React from 'react'

import { PageTemplate, CompletePurchasesTemplate } from '../../templates'

const CompletePurchases: React.FC = () => {
  return (
    <PageTemplate>
      <CompletePurchasesTemplate />
    </PageTemplate>
  )
}

export default CompletePurchases
