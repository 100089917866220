import { IBalance } from '../../interfaces'

export const balanceMock: IBalance[] = [
  {
    amountReceivable: 20,
    amountReceived: 100,
    date: '2022-01-06T11:30:36.228Z',
    partsSold: 30,
    purchasedParts: 23
  },
  {
    amountReceivable: 100,
    amountReceived: 50,
    date: '2022-03-06T11:30:36.228Z',
    partsSold: 30,
    purchasedParts: 40
  },
  {
    amountReceivable: 100,
    amountReceived: 50,
    date: '2022-05-06T11:30:36.228Z',
    partsSold: 30,
    purchasedParts: 40
  },
  {
    amountReceivable: 100,
    amountReceived: 50,
    date: '2022-07-06T11:30:36.228Z',
    partsSold: 30,
    purchasedParts: 40
  },
  {
    amountReceivable: 100,
    amountReceived: 50,
    date: '2023-01-06T11:30:36.228Z',
    partsSold: 30,
    purchasedParts: 40
  }
]
