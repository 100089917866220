import { IUser } from '../interfaces'
import { showErrorToast, showSuccessToast } from '../utils'
import { axiosInstance } from './api'

export async function loginService(email: string) {
  try {
    const response = await axiosInstance.post('/auth/login', { email })
    if (!response.data.success) {
      showErrorToast(
        'Algo deu errado, verifique o email digitado e tente novamente!'
      )
    } else {
      showSuccessToast('Verifique o código enviado para o email digitado!')
      return response.data
    }

    showSuccessToast('Verifique o código enviado pra o e-mail digitado!')
    return response.data
  } catch (err) {
    console.error(err)
    showErrorToast(
      'Algo deu errado, verifique o email digitado e tente novamente!'
    )
  }
}

export async function checkingTypedEmail({
  code,
  email
}: {
  email: string
  code: string
}) {
  try {
    const response = await axiosInstance.post('/auth/verify', { email, code })
    if (!response.data.success) return

    showSuccessToast('E-mail veficado com sucesso!')
    return response.data
  } catch (err) {
    console.error(err)
    showErrorToast(
      'Algo deu errado, verifique o email ou código digitado e tente novamente!'
    )
  }
}

export async function verifyAccessToken(token: string, email: string) {
  try {
    const response = await axiosInstance.post(
      '/auth/check',
      { email },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
    )

    if (!response.data.success) return

    return response.data
  } catch (err) {
    console.error(err)
    showErrorToast('Algo deu errado, Por favor faça login novamente!')
  }
}

export async function termsService(user: IUser) {
  try {
    const response = await axiosInstance.post('/auth/accept-terms', user, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`
      }
    })
    if (!response.data.success) return

    return response.data
  } catch (err) {
    console.error(err)
    showErrorToast('Algo deu errado, Por favor faça login novamente!')
  }
}
