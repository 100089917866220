import React, {
  useState,
  Fragment,
  useEffect,
  useContext,
  ChangeEvent,
  useCallback
} from 'react'

import { Link } from 'react-router-dom'
import { ICategorie } from '../../interfaces'

import { Container } from '../../layout'
import { routesNameEnum } from '../../routes/routesNameEnum'

import {
  InputComponent,
  CustomerInfoLabel,
  CategorySelectComponent,
  LoadingComponent
} from '../../components'

import { useLoading } from '../../hooks'
import { DAZ_DEFAULT_ICON } from '../../utils'
import { fetchCategories } from '../../services'

import { tokenAccessContext } from '../../contexts/TokenAccess/tokenAccessContext'

import * as S from './styles'
import { selectedCustomerContext } from '../../contexts/SelectedCustomer/selectedCustomer'

const CategoriesTemplate: React.FC = () => {
  const { token } = useContext(tokenAccessContext)
  const { isLoading, startLoading, stopLoading } = useLoading()
  const { customer } = useContext(selectedCustomerContext)

  const [searchString, setSearchString] = useState('')
  const [listOfTheCategories, setListOfTheCategories] = useState<ICategorie[]>(
    []
  )

  const [selectedCategory, setSelectedCategory] = useState('')
  const [categoriesData, setCategoriesData] = useState<ICategorie[]>([])

  const getAllCategories = () => {
    ;(async () => {
      try {
        startLoading()
        const response = await fetchCategories(token.accessToken)
        if (response?.success) {
          const categoriesFormatted = response.data.map((category) => {
            return {
              ...category,
              image: category.image || DAZ_DEFAULT_ICON
            }
          })
          setCategoriesData(categoriesFormatted)
          setListOfTheCategories(categoriesFormatted)
        }
      } catch (err) {
        console.log(err)
      } finally {
        stopLoading()
      }
    })()
  }

  const handleChangeSearchValue = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      setSearchString(value)
      if (categoriesData.length) {
        const categoriesFiltered = categoriesData.filter((categorie) =>
          categorie.name.toLowerCase().includes(value.toLowerCase())
        )
        setListOfTheCategories(categoriesFiltered)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [categoriesData]
  )

  const handleChangeSelectedCategorie = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      setSelectedCategory(value)
    },
    []
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getAllCategories, [token])

  return (
    <Fragment>
      <CustomerInfoLabel
        label={`Cliente: ${customer?.email ? customer?.email : customer?.name}`}
      />
      <Container>
        <S.CategoriesTemplateWrapper>
          <S.SearchAreaWrapper>
            <S.ScreenTitle>Selecione a Categoria</S.ScreenTitle>
            <InputComponent
              value={searchString}
              onChange={handleChangeSearchValue}
              placeholder="Buscar"
            />
          </S.SearchAreaWrapper>

          <S.CategoriesList>
            {isLoading ? (
              <LoadingComponent />
            ) : listOfTheCategories.length ? (
              listOfTheCategories.map((item) => (
                <S.CategoriesWrapper key={item.id}>
                  <Link to={`${routesNameEnum.SELECT_BRAND}/${item.id}`}>
                    <S.CategorieItem>
                      <CategorySelectComponent
                        label={item.name}
                        option={item.name}
                        icon={String(item.image)}
                        selectedCategorie={selectedCategory}
                        handleChangeSelectCategorie={
                          handleChangeSelectedCategorie
                        }
                      />
                    </S.CategorieItem>
                  </Link>
                </S.CategoriesWrapper>
              ))
            ) : (
              <S.ScreenTitle>Categoria não encontrada</S.ScreenTitle>
            )}
          </S.CategoriesList>
        </S.CategoriesTemplateWrapper>
      </Container>
    </Fragment>
  )
}

export default CategoriesTemplate
