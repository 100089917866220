import React from 'react'

import { PageTemplate, PurchaseCompleteTemplate } from '../../templates'

const PurchaseComplete: React.FC = () => {
  return (
    <PageTemplate>
      <PurchaseCompleteTemplate />
    </PageTemplate>
  )
}

export default PurchaseComplete
