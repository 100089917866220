import styled from 'styled-components'
import { themeColors } from '../../styles/theme.colors'

export const CategoriesTemplateWrapper = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const SearchAreaWrapper = styled.section`
  width: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30px;
`

export const ScreenTitle = styled.span`
  font-size: 2.2rem;
  color: ${themeColors.black};
  display: inline-block;
  margin-bottom: 32px;
  text-align: center;
`

export const CategoriesList = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const CategoriesWrapper = styled.ul`
  max-height: 650px;
  overflow: hidden;
  overflow-y: scroll;
`

export const CategorieItem = styled.li`
  margin-bottom: 30px;
`
