import React, { Fragment } from 'react'

import { ReactComponent as CloseIcon } from '../../assets/images/close-icon.svg'

import { IModalComponentProps } from './types'

import * as S from './styles'

const ModalComponent: React.FC<IModalComponentProps> = ({
  onClose,
  children,
  isActive,
  withBackground = false,
  titleModal = 'Precisando de ajuda?',
  fillAllHeight = false
}) => {
  return (
    <Fragment>
      {isActive ? (
        <Fragment>
          <S.Overlay withBackground={withBackground} onClick={onClose} />
          <S.ModalContainer isActive={isActive} fillAllHeight={fillAllHeight}>
            <S.ModalHeaderWrapper>
              <S.TitleModal>{titleModal}</S.TitleModal>
              <S.CloseButton onClick={onClose}>
                <CloseIcon />
              </S.CloseButton>
            </S.ModalHeaderWrapper>
            <S.ModalContent fillAllHeight={fillAllHeight}>
              {children}
            </S.ModalContent>
          </S.ModalContainer>
        </Fragment>
      ) : null}
    </Fragment>
  )
}

export default ModalComponent
