import { ReactNode } from 'react'
import { ButtonWrapper } from './styles'

export enum ButtonStyleTypeEnum {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY'
}

export interface IButtonComponentProps
  extends React.ComponentProps<typeof ButtonWrapper> {
  children: ReactNode
  styleType?: ButtonStyleTypeEnum
  formatType?: 'DEFAULT' | 'RECTANGULAR'
}

export interface IStyleProps {
  styleType?: ButtonStyleTypeEnum
  formatType: 'DEFAULT' | 'RECTANGULAR'
}
