import styled from 'styled-components'
import { themeColors } from '../../styles/theme.colors'

export const HelpModalWrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

export const HelpLinkWrapper = styled.div`
  width: 100%;
  gap: 18px;
  display: flex;
  align-items: center;
  margin-bottom: 32px;
`

export const HelpLinkTitle = styled.span`
  color: ${themeColors.black};
  font-size: 2.2rem;
  display: block;
`

export const HelpLinkDescription = styled.span`
  color: ${themeColors.black};
  font-size: 1.4rem;
`
