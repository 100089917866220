import React from 'react'

import { IToggleTypeComponentProps } from './types'

import * as S from './styles'

const ToggleTypeComponent: React.FC<IToggleTypeComponentProps> = ({
  valueOne,
  valueTwo,
  handleChange,
  selectedValue
}) => {
  return (
    <S.ToggleWrapper>
      <label
        className={`label label-type-one ${
          selectedValue === valueOne ? 'selected-option' : ''
        }`}
        htmlFor={valueOne}
      >
        <input
          type="radio"
          id={valueOne}
          value={valueOne}
          onChange={handleChange}
          checked={selectedValue === valueOne}
        />
        {valueOne}
      </label>
      <label
        className={`label label-type-two ${
          selectedValue === valueTwo ? 'selected-option' : ''
        }`}
        htmlFor={valueTwo}
      >
        <input
          type="radio"
          id={valueTwo}
          value={valueTwo}
          onChange={handleChange}
          checked={selectedValue === valueTwo}
        />
        {valueTwo}
      </label>
    </S.ToggleWrapper>
  )
}

export default ToggleTypeComponent
