import React, { createContext, useEffect, useState } from 'react'

import { clearHomeTutorial, showTutorial } from './actions'

import {
  IHomeTutorialContextProviderProps,
  IHomeTutorialContextType
} from './types'

const INITIAL_STATE: IHomeTutorialContextType = {
  showHomeTutorialState: false
}

export const homeTutorialContext =
  createContext<IHomeTutorialContextType>(INITIAL_STATE)

const HomeTutorialContextProvider = ({
  children
}: IHomeTutorialContextProviderProps) => {
  const [showHomeTutorialState, setShowHomeTutorialState] =
    useState<boolean>(false)

  const showHomeTutorialFn = (state: boolean) => {
    setShowHomeTutorialState(showTutorial(state))
  }

  const resetStateOfHomeTutorial = () => {
    window.localStorage.removeItem('home_tutorial_state')
    setShowHomeTutorialState(clearHomeTutorial())
  }

  const savingHomeTutorialStateInLocastorage = () => {
    const dataOfTheLocal = window.localStorage.getItem('home_tutorial_state')
    if (dataOfTheLocal === null) {
      window.localStorage.setItem('home_tutorial_state', JSON.stringify(true))
      return
    }
    window.localStorage.setItem(
      'home_tutorial_state',
      JSON.stringify(showHomeTutorialState)
    )
  }

  const fillingHomeTutorialStateWithDatasAlreadySavedInLocalstorage = () => {
    const dataLocal = window.localStorage.getItem('home_tutorial_state')
    if (dataLocal !== null) {
      setShowHomeTutorialState(JSON.parse(dataLocal))
    }
  }

  useEffect(fillingHomeTutorialStateWithDatasAlreadySavedInLocalstorage, [])
  useEffect(savingHomeTutorialStateInLocastorage, [showHomeTutorialState])

  const value: IHomeTutorialContextType = {
    showHomeTutorialFn,
    showHomeTutorialState,
    resetStateOfHomeTutorial
  }

  return (
    <homeTutorialContext.Provider value={value}>
      {children}
    </homeTutorialContext.Provider>
  )
}

export default HomeTutorialContextProvider
