import styled from 'styled-components'
import { themeColors } from '../../styles/theme.colors'

export const PurchaseCompleteTemplateWrapper = styled.section`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const ContentWrapper = styled.section`
  width: 100%;
  height: 70vh;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

export const ButtonConfirmWrapper = styled.section``

export const OrderCodeWrapper = styled.section`
  width: 100%;
  padding: 18px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 30px;
  border-radius: 20px;
  border: 3px solid ${themeColors.primary_color};
`

export const OrderCodeText = styled.span`
  font-size: 3rem;
  text-transform: uppercase;
  color: ${themeColors.black};

  @media screen and (max-width: 360px) {
    font-size: 2.2rem;
  }
`

export const ScreenTitle = styled.span`
  font-size: 2.2rem;
  text-align: center;
  display: inline-block;
  margin-bottom: 30px;
  color: ${themeColors.black};
`

export const ShareButtonText = styled.span`
  font-size: 2.2rem;
  color: ${themeColors.black};
`

export const ShareButton = styled.button`
  border: none;
  display: flex;
  gap: 12px;
  align-items: center;
  background-color: transparent;
`
