import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body {
    font-family: 'Brandon Regular';
  }
  html {
    font-size: 62.5%;
  }
	a {
		color: #090809;
		text-decoration: none;
	}

  ul {
    list-style: none;
  }

  html, body {
    height: 100%;
  }

  .Toastify__toast-body {
    > div:nth-child(2) {
      font-family: 'Brandon Regular';
      font-size: 1.6rem;
    }
  }

  #root {
    min-height: 100vh;
  }
  `
