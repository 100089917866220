import { axiosInstance } from './api'

import { showErrorToast } from '../utils'
import { IFinalPrices } from '../interfaces'
// import { IProductQuality, IPaginateData } from '../interfaces'

export async function fetchQuality(
  authToken: string,
  category: number,
  brand: number,
  gender: string,
  size: string
) {
  try {
    const response = await axiosInstance.get(
      `/products?category_id=${category}&brand_id=${brand}&gender_id=${gender}&size_id=${size}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    )
    return response.data
  } catch (err) {
    showErrorToast('Algo deu errado, tente novamente!')
  }
}

export async function getMethodsPayment(authToken: string) {
  try {
    const response = await axiosInstance.get('/shopper/methodsPayment', {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })
    return response.data
  } catch (err) {
    console.error(err)
    showErrorToast('Algo deu errado, tente novamente!')
  }
}

export async function finalPricesSave(
  authToken: string,
  requestBody: IFinalPrices
) {
  try {
    const response = await axiosInstance.post(
      '/shopper/finalPrices',
      requestBody,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      }
    )
    if (!response.data.success) return null
    return response.data
  } catch (err) {
    console.error(err)
    showErrorToast(
      'Algo deu errado ao calcular o valor total da compra. Por favor, tente novamente!'
    )
    return null
  }
}
