import styled, { css } from 'styled-components'
import { themeColors } from '../../styles/theme.colors'
import { BoxSizesEnum, IStyleProps } from './types'

const boxFormatStyle = {
  [BoxSizesEnum.LITTLE]: () => css`
    max-width: 169px;
    padding: 4px;
  `,
  [BoxSizesEnum.HIGH]: () => css`
    max-width: 169px;
    padding: 16px;
  `,
  [BoxSizesEnum.WIDE]: () => css`
    max-width: 362px;
    padding: 24px;
  `
}

const boxMessageSize = {
  [BoxSizesEnum.LITTLE]: () => css`
    font-size: 1.9rem;
    max-width: 153.59px;
  `,
  [BoxSizesEnum.HIGH]: () => css`
    font-size: 1.9rem;
    max-width: 158px;
  `,
  [BoxSizesEnum.WIDE]: () => css`
    font-size: 3rem;
    max-width: 300px;
  `
}

export const BoxMessageWrapper = styled.div<IStyleProps>`
  width: 100%;
  position: relative;
  border-radius: 5px;
  background-color: ${themeColors.white};
  border: 3px solid ${themeColors.primary_color};
  ${(props) => props.size && boxFormatStyle[props.size]()}

  display: flex;
  justify-content: center;
`

export const BoxMessageText = styled.span<IStyleProps>`
  text-align: center;
  display: inline-block;
  color: ${themeColors.black};
  ${(props) => props.size && boxMessageSize[props.size]()}
`

export const ArrowUp = styled.div`
  display: block;
  position: absolute;

  left: 16px;
  top: -18px;

  background-color: transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 16px solid ${themeColors.primary_color};
`
