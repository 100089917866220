import React from 'react'

import { IInputComponenttProps } from './types'

import { themeColors } from '../../styles/theme.colors'

import * as S from './styles'

const InputComponent: React.FC<IInputComponenttProps> = ({
  color = themeColors.grey_input_color,
  errorMessage,
  ...rest
}) => {
  return (
    <S.InputWrapper>
      <S.InputElement inputColor={color} {...rest} />
      {errorMessage && <S.ErrorMessage>{errorMessage}</S.ErrorMessage>}
    </S.InputWrapper>
  )
}

export default InputComponent
