import styled, { css } from 'styled-components'

import { ILogoComponentStyleProps } from './types'

export const LogoImage = styled.img<ILogoComponentStyleProps>`
  width: 100%;
  ${(props) =>
    props.imageSize === 'SMALL'
      ? css`
          max-width: 111px;
        `
      : css`
          max-width: 226px;
        `}
`
