import { ReactNode } from 'react'
import { routesNameEnum } from '../../../routes/routesNameEnum'

export interface IHomeNavigationButton {
  children: ReactNode
  bgColor?: ButtonBgColorEnum
  link: routesNameEnum
  available?: boolean
}

export interface IStyleProps {
  bgColor?: string
}

export enum ButtonBgColorEnum {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  BEIGE = 'BEIGE',
  BLUE = 'BLUE',
  GREEN = 'GREEN'
}
