import styled from 'styled-components'

export const TutorialOfTheHomeComponentWrapper = styled.section`
  width: 100%;
  position: absolute;
  min-height: 100vh;
  padding: 8px;
  pointer-events: none;
`

export const TutorialHeaderWrapper = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
`
export const TutorialCenterWrapper = styled.section`
  width: 100%;
  padding: 12px;

  display: flex;
  justify-content: center;
`
