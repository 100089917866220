import React from 'react'

import styled from 'styled-components'

export const LoadingWrapper = styled.section`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LoadingContainer: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  return <LoadingWrapper>{children}</LoadingWrapper>
}

export default LoadingContainer
