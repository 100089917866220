import styled from 'styled-components'
import { themeColors } from '../../styles/theme.colors'

export const LoginFormWrapper = styled.form`
  width: 100%;
  max-width: 768px;
  min-height: 100vh;
  margin: 0 auto;

  padding: 48px 32px;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  .input-email {
    text-align: center;
    color: ${themeColors.primary_color};

    &::placeholder {
      color: ${themeColors.primary_color};
    }
  }
`

export const TermsWrapper = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 100%;
  max-width: 768px;
  min-height: 100vh;
  margin: 0 auto;

  padding: 48px 32px;
`

export const TermWrapper = styled.div`
  /* min-height: 356px;
  max-height: 356px;
  overflow-y: scroll; */
  padding: 16px;
  margin-bottom: 76px;

  display: flex;
  align-items: center;
  flex-direction: column;

  p {
    h3 {
      margin-bottom: 24px;
    }
  }
`

export const TermTitle = styled.span`
  font-size: 14px;
  display: inline-block;
  margin-bottom: 16px;
`

export const TermDescription = styled.p`
  font-size: 1.8rem;
  text-align: center;
  color: ${themeColors.black};
`

export const TermsOfUseTitle = styled.span`
  font-size: 3rem;
  color: ${themeColors.black};
  margin: 46px 0px 24px;
`

export const LoginButton = styled.div`
  position: fixed;
  z-index: 2;
  bottom: 0;
  text-align: center;
  padding: 40px 0;
  width: 100%;
  background-color: white;

  button {
    max-width: 85%;
  }
`
