import React from 'react'

import { PageTemplate, CartTemplate } from '../../templates'

const Cart: React.FC = () => {
  return (
    <PageTemplate>
      <CartTemplate />
    </PageTemplate>
  )
}

export default Cart
