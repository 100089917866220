import React from 'react'

import { PageTemplate, RegisterCustomerTemplate } from '../../templates'

const RegisterCustomer: React.FC = () => {
  return (
    <PageTemplate>
      <RegisterCustomerTemplate />
    </PageTemplate>
  )
}

export default RegisterCustomer
