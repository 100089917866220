import React from 'react'
import { Header } from '../../components'
import { IPageTemplateProps } from './types'

import * as S from './styles'

const PageTemplate: React.FC<IPageTemplateProps> = ({ children }) => {
  return (
    <S.PageTemplateWrapper>
      <Header />
      {children}
    </S.PageTemplateWrapper>
  )
}

export default PageTemplate
