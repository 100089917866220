import { ISelectOption } from './selectOption'

export interface IProduct {
  id: string
  dazPrice: string
  description: string
  productImage: string
  tissueOption: string
  customerPrice: string
  itemEnabledForPurchase?: boolean
  brandId: number
  categoryId: number
}

export interface IProductQuality {
  image: string
  cost: number
  payout: number
  type: IProductTypeEnum
}

export enum IProductTypeEnum {
  MAX = 'MAX',
  MED = 'MED',
  MIN = 'MIN'
}

export interface IProductGenderSize {
  gender: ISelectOption
  size: ISelectOption
}
