import React, { useEffect, useState } from 'react'

import { ISelectComponentProps } from './types'
import { themeColors } from '../../styles/theme.colors'

import * as S from './styles'

const SelectComponent: React.FC<ISelectComponentProps> = ({
  options,
  handleSelectedOption,
  selectedOption,
  searchString,
  handleChangeSearchValue,
  isSearchSelect,
  setSearchString
}) => {
  const [showSelectOptions, setShowSelectOptions] = useState(false)
  const [labelOfSelectedMethod, setLabelOfSelectedMethod] = useState('')

  const handleShowSelectOptions = () => {
    setShowSelectOptions(!showSelectOptions)
  }

  const settingLabelOfSelectedMethod = () => {
    const optionSelected = options.find(
      (option) => option.value === selectedOption.value
    )

    if (optionSelected) {
      setLabelOfSelectedMethod(optionSelected.label)
      setSearchString?.(optionSelected.label)
      return
    }
  }

  const changeColorWhenSelected = labelOfSelectedMethod
    ? themeColors.secondary_color
    : themeColors.toggle_offColor

  useEffect(settingLabelOfSelectedMethod, [
    options,
    selectedOption,
    setSearchString
  ])

  useEffect(() => {
    searchString && setSearchString?.(searchString)
  }, [searchString, setSearchString])

  return (
    <S.Wrapper>
      {showSelectOptions && <S.Overlay onClick={handleShowSelectOptions} />}
      <S.SelectComponentWrapper
        onClick={handleShowSelectOptions}
        color={changeColorWhenSelected}
      >
        <S.SelectedOptionAndButtonWrapper>
          {isSearchSelect ? (
            <S.SearchInput
              value={searchString}
              onChange={handleChangeSearchValue}
              placeholder="BUSCAR"
            />
          ) : (
            <S.SelectedOptionLabel color={changeColorWhenSelected}>
              {labelOfSelectedMethod ? labelOfSelectedMethod : 'Selecione'}
            </S.SelectedOptionLabel>
          )}
          <S.ShowOptionsButton onClick={handleShowSelectOptions}>
            {showSelectOptions ? (
              <S.UpTriangleElement color={changeColorWhenSelected} />
            ) : (
              <S.DownTriangleElement color={changeColorWhenSelected} />
            )}
          </S.ShowOptionsButton>
        </S.SelectedOptionAndButtonWrapper>
      </S.SelectComponentWrapper>
      {showSelectOptions && (
        <S.OptionsWrapper color={changeColorWhenSelected}>
          {options.map((option) => (
            <S.SelectOptionLabel
              htmlFor={option.value}
              key={option.value}
              optionSelected={selectedOption.value === option.value}
              color={changeColorWhenSelected}
            >
              <input
                type="radio"
                id={option.value}
                value={option.value}
                onChange={() => handleSelectedOption(option)}
                onClick={() => setShowSelectOptions(false)}
                checked={selectedOption.value === option.value}
              />
              {option.label}
            </S.SelectOptionLabel>
          ))}
        </S.OptionsWrapper>
      )}
    </S.Wrapper>
  )
}

export default SelectComponent
