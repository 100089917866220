import styled from 'styled-components'
import { themeColors } from '../../styles/theme.colors'
import { IStyleProps } from './types'

export const CategorieBedgeWrapper = styled.label<IStyleProps>`
  width: 100%;
  padding: 13px 20px;
  background-color: ${({ categorySelected }) =>
    categorySelected ? themeColors.grey_5 : themeColors.dark_white};

  display: flex;
  gap: 20px;
  align-items: center;

  input[type='radio'] {
    display: none;
  }
`

export const CategorieBedgeLabel = styled.span`
  font-size: 2.4rem;
  font-weight: 600;
  display: inline-block;
  color: ${themeColors.black};
`

export const CategorieBedgeIcon = styled.img`
  max-width: 42px;
  max-height: 42px;
`
