import styled, { css } from 'styled-components'
import { themeColors } from '../../../styles/theme.colors'
import { ButtonBgColorEnum, IStyleProps } from './types'

export const HomeNavigationButtonWrapper = styled.button<IStyleProps>`
  display: block;
  width: 100%;
  min-width: 100%;
  font-size: 2rem;
  border: none;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  ${(props) => {
    const bgColorValue = props.bgColor
    const bgColor =
      bgColorValue === ButtonBgColorEnum.PRIMARY
        ? themeColors.secondary_color
        : bgColorValue === ButtonBgColorEnum.SECONDARY
        ? themeColors.primary_color
        : bgColorValue === ButtonBgColorEnum.BEIGE
        ? themeColors.beige_color
        : bgColorValue === ButtonBgColorEnum.BLUE
        ? themeColors.blue_color
        : themeColors.green_color

    const buttonColor =
      bgColorValue === ButtonBgColorEnum.BEIGE ||
      bgColorValue === ButtonBgColorEnum.GREEN
        ? themeColors.black
        : themeColors.white

    return css`
      color: ${buttonColor};
      background-color: ${bgColor};
    `
  }}
`
