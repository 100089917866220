import React from 'react'

import { CategoriesTemplate, PageTemplate } from '../../templates'

const Categories: React.FC = () => {
  return (
    <PageTemplate>
      <CategoriesTemplate />
    </PageTemplate>
  )
}

export default Categories
