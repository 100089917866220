import React from 'react'

import { PageTemplate, ProductQualityTemplate } from '../../templates'

const ProductQuality: React.FC = () => {
  return (
    <PageTemplate>
      <ProductQualityTemplate />
    </PageTemplate>
  )
}

export default ProductQuality
