import { ICartItem } from '../interfaces'

export const totalPayableToTheClient = (cartItems: ICartItem[]) => {
  return cartItems.reduce((total, value) => (total += Number(value.price)), 0)
}

export const totalReceivableFromDaz = (cartItems: ICartItem[]) => {
  return cartItems.reduce(
    (total, value) => (total += Number(value.dazPrice)),
    0
  )
}
