import React, { createContext, useEffect, useState } from 'react'

import { addGenderSize, clearGenderSize } from './actions'
import { IProductGenderSize } from '../../interfaces'

import {
  ISelectedGenderSizeContextType,
  ISelectedGenderSizeProps
} from './types'
import {
  fillingDataWithDatasAlreadySavedInLocalstorage,
  savingDataInLocastorage
} from '../../utils'

const INITIAL_STATE = {
  genderSize: null
}

export const selectedGenderSizeContext =
  createContext<ISelectedGenderSizeContextType>(INITIAL_STATE)

const SelectedGenderSizeContextProvider = ({
  children
}: ISelectedGenderSizeProps) => {
  const [genderSize, setGenderSize] =
    useState<Partial<IProductGenderSize> | null>(null)

  const saveGenderSize = (genderSize: IProductGenderSize) => {
    setGenderSize(addGenderSize(genderSize))
  }

  const resetGenderSize = () => {
    window.localStorage.removeItem('genderSize')
    setGenderSize(clearGenderSize())
  }

  const localStorageSave = () => {
    savingDataInLocastorage(genderSize, 'genderSize', null)
  }

  const fillingGenderSize = () => {
    fillingDataWithDatasAlreadySavedInLocalstorage(setGenderSize, 'genderSize')
  }

  useEffect(fillingGenderSize, [])
  useEffect(localStorageSave, [genderSize])

  const value: ISelectedGenderSizeContextType = {
    genderSize,
    saveGenderSize,
    resetGenderSize
  }
  return (
    <selectedGenderSizeContext.Provider value={value}>
      {children}
    </selectedGenderSizeContext.Provider>
  )
}

export default SelectedGenderSizeContextProvider
