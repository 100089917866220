import React, { useEffect, useReducer, useRef } from 'react'

import { Context } from './cart.context'
import { cartReducer } from './cart.reducer'
import { CART_INITIAL_STATE } from './cart.data'
import { CartProviderTypes } from './types'
import { buildActions } from './cart.build-actions'
import { CART_ACTIONS_TYPES } from './cart.actions-types'

import { ContextProvider } from '../../interfaces'
import { totalPayableToTheClient, totalReceivableFromDaz } from '../../utils'

const CartContextProvider: React.FC<ContextProvider> = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, CART_INITIAL_STATE)
  const actions = useRef(buildActions(dispatch)).current

  const dazTotalPrice = () => totalReceivableFromDaz(state.cartItems)
  const customerTotalPrice = () => totalPayableToTheClient(state.cartItems)

  const savingCartItemsInLocastorage = () => {
    const dataOfTheLocal = window.localStorage.getItem('cartItems')
    if (!state.cartItems.length && !dataOfTheLocal) {
      window.localStorage.setItem('cartItems', JSON.stringify([]))
      return
    }
    if (state.cartItems.length) {
      window.localStorage.setItem('cartItems', JSON.stringify(state.cartItems))
    }
  }

  const fillingCartWithItemsAlreadySavedInLocalstorage = () => {
    const dataLocal = window.localStorage.getItem('cartItems')
    if (dataLocal) {
      dispatch({
        type: CART_ACTIONS_TYPES.SET_CART_ITEMS,
        payload: JSON.parse(dataLocal)
      })
    }
  }

  useEffect(fillingCartWithItemsAlreadySavedInLocalstorage, [])
  useEffect(savingCartItemsInLocastorage, [state])

  const value: CartProviderTypes = {
    actions,
    dazTotalPrice,
    customerTotalPrice,
    cartItems: state.cartItems
  }

  return <Context.Provider value={{ ...value }}>{children}</Context.Provider>
}

export default CartContextProvider
