import React from 'react'

import { BrandsTemplate, PageTemplate } from '../../templates'

const Brands: React.FC = () => {
  return (
    <PageTemplate>
      <BrandsTemplate />
    </PageTemplate>
  )
}

export default Brands
