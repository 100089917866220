import styled from 'styled-components'
import { themeColors } from '../../styles/theme.colors'

export const BalanceTemplateWrapper = styled.section``

export const BalanceHeader = styled.header`
  width: 100%;
  gap: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
  margin-top: 42px;
`

export const NextButton = styled.button`
  width: 0;
  height: 0;
  border: none;
  transform: rotate(90deg);
  background-color: transparent;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 32px solid ${themeColors.secondary_color};
`

export const SelectedPeriod = styled.span`
  font-size: 2.6rem;
  color: ${themeColors.black};
`

export const PrevButton = styled(NextButton)`
  transform: rotate(-90deg);
`

export const InfosOfTheBalanceWrapper = styled.ul``

export const InfoOfTheBalanceItem = styled.li`
  font-size: 1.8rem;
  margin-bottom: 52px;
  color: ${themeColors.black};
`
