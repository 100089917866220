import React from 'react'

import { BoxSizesEnum, IBoxMessageComponentProps } from './types'

import * as S from './styles'

const BoxMessageComponent: React.FC<IBoxMessageComponentProps> = ({
  message,
  size = BoxSizesEnum.LITTLE,
  ...props
}) => {
  return (
    <S.BoxMessageWrapper {...props} size={size}>
      <S.BoxMessageText size={size}>{message}</S.BoxMessageText>
      <S.ArrowUp />
    </S.BoxMessageWrapper>
  )
}

export default BoxMessageComponent
