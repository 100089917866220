import React from 'react'

import { ICustomerInfoLabelProps } from './types'

import * as S from './styles'

const CustomerInfoLabel: React.FC<ICustomerInfoLabelProps> = ({ label }) => {
  return <S.CustomerInforLabelElement>{label}</S.CustomerInforLabelElement>
}

export default CustomerInfoLabel
