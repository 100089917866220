import React from 'react'

import { ISelectQuantityComponentProps } from './types'

import * as S from './styles'

const SelectQuantityComponent: React.FC<ISelectQuantityComponentProps> = ({
  quantity,
  onChange,
  decreaseFn,
  increaseFn
}) => {
  return (
    <S.Wrapper>
      <S.DecreaseButton onClick={decreaseFn}>-</S.DecreaseButton>
      <S.InputComponent
        value={quantity}
        onChange={onChange}
        type="text"
        inputMode="numeric"
        maxLength={2}
      />
      <S.IncreaseButton onClick={increaseFn}>+</S.IncreaseButton>
    </S.Wrapper>
  )
}

export default SelectQuantityComponent
