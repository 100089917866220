import React from 'react'

import { PageTemplate, NewBuyTemplate } from '../../templates'

const NewBuy: React.FC = () => {
  return (
    <PageTemplate>
      <NewBuyTemplate />
    </PageTemplate>
  )
}

export default NewBuy
