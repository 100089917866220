import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { IBalance } from '../../interfaces'
import { formatPrice } from '../../utils'

import { Container } from '../../layout'

import * as S from './styles'

import { balanceMock } from './mock'

const BalanceTemplate: React.FC = () => {
  const [hideNextButton, setHideNextButton] = useState(false)
  const [hidePrevButton, setHidePrevButton] = useState(false)
  const [balanceToBeShownIndex, setBalanceToBeShownIndex] = useState(0)

  const [balanceData, setBalanceData] = useState<IBalance[]>([])

  const getBalance = () => {
    ;(async () => {
      // TODO: switch to data coming from api
      const response = balanceMock
      setBalanceData(response)
    })()
  }

  const monthNames = useMemo(() => {
    return [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro'
    ]
  }, [])

  const handleShowNextBalance = useCallback(
    (arrayOfTheBalances: IBalance[]) => {
      setBalanceToBeShownIndex((prevState) => {
        if (prevState === arrayOfTheBalances.length - 1) return prevState
        return prevState + 1
      })
    },
    []
  )

  const handleShowPrevBalance = useCallback(() => {
    setBalanceToBeShownIndex((prevState) => {
      if (prevState === 0) return 0
      return prevState - 1
    })
  }, [])

  const renderingBalanceInfos = (
    balanceIndex: number,
    monthNames: string[],
    arrayOfTheBalances: IBalance[]
  ) => {
    const {
      date,
      partsSold,
      amountReceived,
      purchasedParts,
      amountReceivable
    } = balanceData[balanceIndex]

    const balanceMonth = monthNames[new Date(date).getMonth()]
    const balanceYear = new Date(date).getFullYear()
    const partsPurchasedLabel = `Peças compradas: ${purchasedParts}`
    const partsSoldLabel = `Peças vendidas: ${partsSold}`
    const valueToBeReceivedLabel = `A receber: ${formatPrice(amountReceivable)}`
    const valueReceivedLabel = `Recebido: ${formatPrice(amountReceived)}`

    return (
      <S.BalanceTemplateWrapper>
        <S.BalanceHeader>
          {!hidePrevButton ? (
            <S.PrevButton onClick={handleShowPrevBalance} />
          ) : (
            <div style={{ width: '32px' }} />
          )}
          <S.SelectedPeriod>
            {balanceMonth}/{balanceYear}
          </S.SelectedPeriod>
          {!hideNextButton ? (
            <S.NextButton
              onClick={() => handleShowNextBalance(arrayOfTheBalances)}
            />
          ) : (
            <div style={{ width: '32px' }} />
          )}
        </S.BalanceHeader>
        <S.InfosOfTheBalanceWrapper>
          <S.InfoOfTheBalanceItem>{partsPurchasedLabel}</S.InfoOfTheBalanceItem>
          <S.InfoOfTheBalanceItem>{partsSoldLabel}</S.InfoOfTheBalanceItem>
          <S.InfoOfTheBalanceItem>
            {valueToBeReceivedLabel}
          </S.InfoOfTheBalanceItem>
          <S.InfoOfTheBalanceItem>{valueReceivedLabel}</S.InfoOfTheBalanceItem>
        </S.InfosOfTheBalanceWrapper>
      </S.BalanceTemplateWrapper>
    )
  }

  const checkingIfWhetherOrNotWeShouldShowThePrevAndNextButtons = useCallback(
    (currentIndexBeingShown: number, arrayOfItemsToBeDisplayed: IBalance[]) => {
      if (arrayOfItemsToBeDisplayed.length) {
        if (currentIndexBeingShown >= arrayOfItemsToBeDisplayed.length - 1) {
          setHideNextButton((prevState) => !prevState)
          return
        }

        if (currentIndexBeingShown <= 0) {
          setHidePrevButton((prevState) => !prevState)
          return
        }

        if (
          currentIndexBeingShown > 0 &&
          currentIndexBeingShown <= arrayOfItemsToBeDisplayed.length - 1
        ) {
          setHideNextButton(false)
          setHidePrevButton(false)
        }
      }
    },
    []
  )

  useEffect(getBalance, [])

  useEffect(() => {
    checkingIfWhetherOrNotWeShouldShowThePrevAndNextButtons(
      balanceToBeShownIndex,
      balanceData
    )
  }, [
    balanceToBeShownIndex,
    balanceData,
    checkingIfWhetherOrNotWeShouldShowThePrevAndNextButtons
  ])

  return (
    <Container>
      {!!balanceData.length &&
        renderingBalanceInfos(balanceToBeShownIndex, monthNames, balanceData)}
    </Container>
  )
}

export default BalanceTemplate
