import styled from 'styled-components'
import { themeColors } from '../../styles/theme.colors'
import { IStyleProps } from './types'

export const TermsWrapper = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  max-width: 768px;
  margin: 0 auto;

  padding: 0 16px;
`

export const ClientInfos = styled.div`
  align-self: flex-start;
  margin-bottom: 16px;
`

export const ClientInfo = styled.p`
  font-size: 1.4rem;
`

export const TermWrapper = styled.div`
  overflow-y: scroll;
  margin-bottom: 2rem;

  display: flex;
  align-items: start;
  flex-direction: column;
  flex-grow: 1;
`

export const TermDescription = styled.p`
  font-size: 1.4rem;
  text-align: start;
  color: ${themeColors.black};
`

export const ItemInfo = styled.p`
  align-self: flex-start;

  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 16px;
`

export const WhatsappBoxWrapper = styled.label`
  display: flex;
  flex-grow: 1;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
`

export const BoxInput = styled.div<IStyleProps>`
  min-width: 48px;
  height: 48px;
  margin-right: 16px;

  background-color: ${({ isBoxChecked }) =>
    isBoxChecked ? themeColors.primary_color : 'trasparent'};

  border: 1px solid ${themeColors.primary_color};

  > input[type='checkbox'] {
    display: none;
  }
`

export const BoxText = styled.span`
  font-size: 1.8rem;
  color: ${themeColors.black};
`

export const ContentButton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  text-align: center;
  margin-bottom: 6px;
  width: 100%;

  button {
    max-width: 100%;
  }
`
