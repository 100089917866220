import React from 'react'

import { IProductSelectComponentProps } from './types'

import { formatPrice } from '../../utils'

import * as S from './styles'

const ProductSelectComponent: React.FC<IProductSelectComponentProps> = ({
  label,
  option,
  dazPrice,
  // customerPrice,
  productImage,
  selectedProductType,
  handleChangeSelectProductType
}) => {
  return (
    <S.ProductSelectComponentWrapper
      selected={option === selectedProductType}
      htmlFor={option}
    >
      <S.ProductImage src={productImage} alt={label} />
      <S.ProductDescriptionWrapper>
        <S.ProductDescription>{label}</S.ProductDescription>
        <S.ProductPrice>Preço: {formatPrice(dazPrice)}</S.ProductPrice>
        {/* <S.ProductPrice>Cliente: {formatPrice(customerPrice)}</S.ProductPrice> */}
        <input
          type="radio"
          id={option}
          name={option}
          value={option}
          onChange={handleChangeSelectProductType}
          checked={option === selectedProductType}
        />
      </S.ProductDescriptionWrapper>
    </S.ProductSelectComponentWrapper>
  )
}

export default ProductSelectComponent
