import React from 'react'

import { ISelectRadioComponentProps } from './types'

import * as S from './styles'

const SelectRadioComponent: React.FC<ISelectRadioComponentProps> = ({
  id,
  label,
  option,
  description,
  handleChange,
  selectedValue,
  ...inputProps
}) => {
  const optionSelected = selectedValue === option

  return (
    <S.SelectRadioWrapper>
      <S.InputRadioWrapper checkedValue={optionSelected}>
        <S.InputRadioElement
          id={id}
          name={id}
          type="radio"
          value={option}
          onChange={handleChange}
          checked={selectedValue === option}
          {...inputProps}
        />
      </S.InputRadioWrapper>
      <S.ValueAndDescriptionWrapper>
        <S.LabelElement htmlFor={id}>{label}</S.LabelElement>
        <S.Description>{description}</S.Description>
      </S.ValueAndDescriptionWrapper>
    </S.SelectRadioWrapper>
  )
}

export default SelectRadioComponent
