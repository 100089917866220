import React, { Fragment, useEffect } from 'react'
import Aos from 'aos'
import { HelpModalComponent } from './components'

import AppRoutes from './routes/App.routes'

function App() {
  useEffect(() => {
    Aos.init({ duration: 1200 })
  }, [])
  return (
    <Fragment>
      <AppRoutes />
      <HelpModalComponent />
    </Fragment>
  )
}

export default App
