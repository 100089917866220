import React from 'react'

import * as S from './styles'

import { ICategorySelectComponentProps } from './types'

const CategorySelectComponent: React.FC<ICategorySelectComponentProps> = ({
  icon,
  label,
  option,
  selectedCategorie,
  handleChangeSelectCategorie
}) => {
  return (
    <S.CategorieBedgeWrapper
      categorySelected={option ? option === selectedCategorie : false}
      htmlFor={option ? option : ''}
    >
      <S.CategorieBedgeIcon src={icon} alt={label} />
      <S.CategorieBedgeLabel>{label}</S.CategorieBedgeLabel>
      {option ? (
        <input
          id={option}
          type="radio"
          name={option}
          value={option}
          onChange={handleChangeSelectCategorie}
          checked={option === selectedCategorie}
        />
      ) : null}
    </S.CategorieBedgeWrapper>
  )
}

export default CategorySelectComponent
