import styled from 'styled-components'
import { themeColors } from '../../styles/theme.colors'
import { IStyleProps } from './types'

export const SelectBrandWrapper = styled.label<IStyleProps>`
  width: 100%;
  height: 77px;
  margin: 0 auto;
  display: flex;
  border-radius: 5px;
  background-color: ${({ selected }) =>
    selected ? themeColors.grey_5 : themeColors.brand_bg_color};

  align-items: center;
  justify-content: center;

  > img {
    max-width: 100%;
    max-height: 100%;
  }

  > input[type='radio'] {
    display: none;
  }
`
