import { IAction } from '../../interfaces'
import { CART_ACTIONS_TYPES } from './cart.actions-types'
import { CART_INITIAL_STATE } from './cart.data'
import { CartStateType } from './types'

export const cartReducer = (
  state = CART_INITIAL_STATE,
  action: IAction
): CartStateType => {
  switch (action.type) {
    case CART_ACTIONS_TYPES.SET_CART_ITEMS: {
      return { ...state, cartItems: action.payload }
    }
    case CART_ACTIONS_TYPES.ADD_ITEM_TO_CART: {
      return { ...state, cartItems: [...state.cartItems, action.payload] }
    }
    case CART_ACTIONS_TYPES.UPDATE_ITEM_OF_THE_CART: {
      return { ...state, cartItems: action.payload }
    }
    case CART_ACTIONS_TYPES.CLEAR_ALL_ITEMS_OF_THE_CART: {
      return { ...state, cartItems: action.payload }
    }
  }
  return { ...state }
}
