import styled from 'styled-components'
import { IStyleProps } from './types'
import { themeColors } from '../../styles/theme.colors'

export const SelectRadioWrapper = styled.div`
  width: 100%;
  gap: 16px;
  display: flex;
  align-items: center;
`

export const InputRadioElement = styled.input`
  cursor: pointer;
`

export const InputRadioWrapper = styled.div<IStyleProps>`
  min-width: 30px;
  min-height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  background-color: ${({ checkedValue }) =>
    checkedValue ? themeColors.secondary_color : themeColors.grey_5};

  > input[type='radio'] {
    opacity: 0;
  }
`

export const LabelElement = styled.label`
  font-size: 2rem;
  color: ${themeColors.black};
`

export const Description = styled.p`
  font-size: 2rem;
  color: ${themeColors.grey_3};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: 500px) {
    font-size: 1.6rem;
  }
  @media screen and (max-width: 400px) {
    font-size: 1.4rem;
  }
`

export const ValueAndDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`
