import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { selectedCustomerContext } from '../../contexts/SelectedCustomer/selectedCustomer'

import { Container } from '../../layout'
import {
  ButtonComponent,
  CartItemComponent,
  CustomerInfoLabel
} from '../../components'

import { showSuccessToast } from '../../utils'
import { routesNameEnum } from '../../routes/routesNameEnum'
import { useCartContext } from '../../contexts/CartProvider/useCartContext'
import { tokenAccessContext } from '../../contexts/TokenAccess/tokenAccessContext'
import { selectedGenderSizeContext } from '../../contexts/SelectedGenderSize/selectedGenderSize'
import { IStandByPurchase, IStandByPurchaseSave } from '../../interfaces'
import {
  standByPurchaseGet,
  standByPurchaseRemove,
  standByPurchaseSave
} from '../../services/standby.service'
import { ButtonStyleTypeEnum } from '../../components/ButtonComponent/types'

import * as S from './styles'

const CartTemplate: React.FC = () => {
  const navigate = useNavigate()
  const {
    cartItems,
    // customerTotalPrice,
    // dazTotalPrice,
    actions: CartContextActions
  } = useCartContext()
  const { token } = useContext(tokenAccessContext)
  const { customer, resetSelectedCustomer } = useContext(
    selectedCustomerContext
  )
  const { genderSize, resetGenderSize } = useContext(selectedGenderSizeContext)

  const [standbyPurchasesList, setStandbyPurchasesList] = useState<
    IStandByPurchase[]
  >([])

  const standById = localStorage.getItem('standById')
  const shopperId = Number(localStorage.getItem('shopperId'))

  const handleGotToAddMoreParts = () =>
    navigate(routesNameEnum.PRODUCT_GENDER_SIZE)

  const removeStandbyPurchaseItem = async () => {
    try {
      const standbyPurchase = standbyPurchasesList.find(
        (item) => standById && item.id === parseInt(standById)
      )

      if (standbyPurchase && standById) {
        const response = await standByPurchaseRemove(
          token.accessToken,
          standById,
          shopperId
        )
        console.log(response)
        const updatedStandbyPurchasesList = standbyPurchasesList.filter(
          (item) => item.id !== parseInt(standById)
        )
        setStandbyPurchasesList(updatedStandbyPurchasesList)
      }
    } catch (error) {
      console.error(`Error removing standby purchase: ${error}`)
    }
  }

  const savePurchaseOnStandby = async () => {
    const requestBody: IStandByPurchaseSave = {
      shopper: shopperId,
      genderSize: genderSize,
      customer: {
        id: customer && customer.id,
        name: customer && customer.name,
        email: customer?.email,
        document: customer?.document
      },
      items: cartItems
    }
    try {
      const response = await standByPurchaseSave(token.accessToken, requestBody)
      if (response) {
        removeStandbyPurchaseItem()
        CartContextActions?.clearCartAction?.()
        resetGenderSize?.()
        resetSelectedCustomer?.()
        showSuccessToast('Compra salva em standby com sucesso!')
        navigate(routesNameEnum.HOME)
      } else {
        return { success: false }
      }
    } catch (error) {
      console.error(`Erro ao salvar em standby: ${error}`)
      return { success: false }
    }
  }

  useEffect(() => {
    async function getStandbyPurchases() {
      try {
        const response = await standByPurchaseGet(token.accessToken, shopperId)
        setStandbyPurchasesList(response.data)
      } catch (error) {
        console.error(`Error getting standby purchases: ${error}`)
      }
    }
    getStandbyPurchases()
  }, [token.accessToken, shopperId])

  const proceedToTheNextStageOfThePurchase = () =>
    navigate(routesNameEnum.ORDER_QUESTIONNAIRE)

  return (
    <Fragment>
      <CustomerInfoLabel
        label={`Cliente: ${customer?.email ? customer?.email : customer?.name}`}
      />
      <Container>
        <S.CartTemplateWrapper>
          <S.ScreenTitle>Coleta atual</S.ScreenTitle>
          <S.CartItemsWrapper>
            {!!cartItems.length &&
              cartItems.map((item) => (
                <S.CartItem key={item.cartItemID}>
                  <CartItemComponent item={item} />
                </S.CartItem>
              ))}
          </S.CartItemsWrapper>
          <S.ButtonAddItemWrapper>
            <ButtonComponent
              onClick={handleGotToAddMoreParts}
              styleType={ButtonStyleTypeEnum.SECONDARY}
            >
              Adicionar peça
            </ButtonComponent>
          </S.ButtonAddItemWrapper>
          <S.AdvanceCollectionButtonsWrapper
            isFixedPosition={cartItems.length === 1}
          >
            <ButtonComponent onClick={savePurchaseOnStandby}>
              Salvar em Standby
            </ButtonComponent>
            <ButtonComponent onClick={proceedToTheNextStageOfThePurchase}>
              Avançar
            </ButtonComponent>
          </S.AdvanceCollectionButtonsWrapper>
        </S.CartTemplateWrapper>
      </Container>
    </Fragment>
  )
}

export default CartTemplate
