import { ISelectOption } from '../../interfaces'

export const motivesMock = [
  {
    id: '7',
    label: 'Peças muito antigas'
  },
  {
    id: '26',
    label: 'Odor das peças'
  },
  {
    id: '8',
    label: 'Peças com defeito'
  },
  {
    id: '27',
    label: 'Peças manchadas/sujas'
  }
]

export const paymentMethodsOptionsMock: ISelectOption[] = [
  { label: 'PIX', value: '1' },
  { label: 'Crédito Daz', value: '2' },
  { label: 'Vale', value: '3' }
]
