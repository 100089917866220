import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  useCallback
} from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { Container } from '../../layout'
import {
  InputComponent,
  CustomerInfoLabel,
  SelectBrandComponent,
  CategorySelectComponent,
  LoadingComponent
} from '../../components'

import { DAZ_DEFAULT_ICON } from '../../utils'
import { IBrand, ICategorie } from '../../interfaces'
import { routesNameEnum } from '../../routes/routesNameEnum'
import { fetchBrands, fetchCategories } from '../../services'
import { selectedCustomerContext } from '../../contexts/SelectedCustomer/selectedCustomer'

import { tokenAccessContext } from '../../contexts/TokenAccess/tokenAccessContext'

import * as S from './styles'
import { useLoading } from '../../hooks'

const BrandsTemplate: React.FC = () => {
  const navigate = useNavigate()
  const { categoryID } = useParams()
  const { customer } = useContext(selectedCustomerContext)
  const { token } = useContext(tokenAccessContext)
  const { isLoading, startLoading, stopLoading } = useLoading()

  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout>()
  const [searchString, setSearchString] = useState('')
  const [category, setCategory] = useState<ICategorie>()
  const [selectedBrand, setSelectedBrand] = useState('')
  const [listOfTheBrands, setListOfTheBrands] = useState<IBrand[]>([])

  // const [currentBrandsPage, setCurrentBrandsPage] = useState(1)
  // CONCLUIR A PÁGINAÇÃO DAS MARCAS NESTA TELA

  const currentBrandsPage = 1
  // delete when pagination is done

  const handleChangeSearchValue = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      setSearchString(value)
    },
    []
  )

  const handleChangeSelectedBrand = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      setSelectedBrand(value)
    },
    []
  )

  const getCategory = () => {
    ;(async () => {
      const response = await fetchCategories(token.accessToken)

      if (response?.success) {
        const category = response.data.find(
          (category) => category.id === Number(categoryID)
        )
        if (!category) return
        setCategory({ ...category, image: category.image || DAZ_DEFAULT_ICON })
      }
    })()
  }

  const letterize = (str: string) => {
    if (str.length > 100) {
      return str
        .split(' ')
        .map((word: string) => word.charAt(0))
        .join('')
        .slice(0, 3)
    }

    return str
  }

  const brandsSearchedBySearchString = useCallback(
    (searchString: string) => {
      if (searchTimeout) {
        clearTimeout(searchTimeout)
      }
      setSearchTimeout(
        setTimeout(async () => {
          try {
            startLoading()
            const response = await fetchBrands(
              token.accessToken,
              currentBrandsPage,
              encodeURIComponent(searchString)
            )
            if (response?.success) {
              const brandsFormatted = response.data.map((brand) => {
                return {
                  ...brand,
                  image: brand.image,
                  etiqueta: brand.etiquetaNaoPermitida
                }
              })
              setListOfTheBrands(brandsFormatted)
            }
          } catch (err) {
            console.log(err)
          } finally {
            stopLoading()
          }
        }, 1000)
      )
    },
    // eslint-disable-next-line
    [searchTimeout, listOfTheBrands]
  )

  const goToProductQualityPage = (brand: IBrand) => {
    navigate(routesNameEnum.CHOICE_OF_PRODUCT_QUALITY, {
      state: { category, brand }
    })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getCategory, [categoryID, token])
  useEffect(() => {
    brandsSearchedBySearchString(searchString)
    // eslint-disable-next-line
  }, [searchString])

  return (
    <Fragment>
      <CustomerInfoLabel
        label={`Cliente: ${customer?.email ? customer?.email : customer?.name}`}
      />
      <Container>
        <S.BrandsTemplateWrapper>
          <S.LinkWrapper>Clique para trocar</S.LinkWrapper>
          {category ? (
            <Link className="link" to={routesNameEnum.CATEGORIES}>
              <S.CategoryBadgeWrapper>
                <CategorySelectComponent
                  label={category.name}
                  icon={String(category.image)}
                />
              </S.CategoryBadgeWrapper>
            </Link>
          ) : null}
          <S.SearchWrapper>
            <S.ScreenTitle>Selecione a Marca</S.ScreenTitle>
            <InputComponent
              placeholder="Buscar"
              value={searchString}
              onChange={handleChangeSearchValue}
            />
          </S.SearchWrapper>

          {!!listOfTheBrands.length && !isLoading ? (
            <S.BrandsOptionsWrapper>
              {listOfTheBrands.map((brand, index) => (
                <S.SelectBrandItem
                  onClick={() => goToProductQualityPage(brand)}
                  key={`${brand.name}-${index}`}
                >
                  <SelectBrandComponent
                    option={String(brand.name)}
                    brandLogo={
                      brand.image ? String(brand.image) : letterize(brand.name)
                    }
                    selectedBrand={selectedBrand}
                    handleChangeSelectBrand={handleChangeSelectedBrand}
                  />
                </S.SelectBrandItem>
              ))}
            </S.BrandsOptionsWrapper>
          ) : searchString && !isLoading ? (
            <S.ScreenTitle>Marca não encontrada</S.ScreenTitle>
          ) : (
            <LoadingComponent />
          )}
        </S.BrandsTemplateWrapper>
      </Container>
    </Fragment>
  )
}

export default BrandsTemplate
