/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useRef, useState } from 'react'

import { IConfirmationCodeInputProps } from './types'

import * as S from './styles'

const ConfirmationCodeInput: React.FC<IConfirmationCodeInputProps> = ({
  code,
  showInvalidCodeMessage,
  setShowInvalidCodeMessage,
  handleChangeConfirmationCodeValue,
  showConfirmationCodeInputs
}) => {
  const codePositions = Object.keys(code)
  const inputRefChar1 = useRef<HTMLInputElement>(null)
  const inputRefChar2 = useRef<HTMLInputElement>(null)
  const inputRefChar3 = useRef<HTMLInputElement>(null)
  const inputRefChar4 = useRef<HTMLInputElement>(null)
  const [finalCodeState, setFinalCodeState] = useState('')
  const [showInvalidCodeError, setShowInvalidCodeError] = useState(false)

  const handleOnBlur = () => {
    let finalCodeValue = ''
    for (const key in code) {
      const currentKey = key as keyof typeof code
      finalCodeValue += code[currentKey]
    }

    setShowInvalidCodeMessage(finalCodeValue.length < 4)
    setShowInvalidCodeError(
      finalCodeValue.length === 4 && showConfirmationCodeInputs
    )

    //clean the inputs if the code is invalid
    if (finalCodeValue.length === 4) {
      code.char1 = ''
      code.char2 = ''
      code.char3 = ''
      code.char4 = ''
    }
  }

  const puttingTheInputsInFocus = () => {
    if (code.char1.length === 0) {
      inputRefChar1.current?.focus()
    } else if (code.char1.length > 0 && code.char2.length === 0) {
      inputRefChar2.current?.focus()
    } else if (
      code.char1.length > 0 &&
      code.char2.length > 0 &&
      code.char3.length === 0
    ) {
      inputRefChar3.current?.focus()
    } else if (
      code.char1.length > 0 &&
      code.char2.length > 0 &&
      code.char3.length > 0 &&
      code.char4.length === 0
    ) {
      inputRefChar4.current?.focus()
    }
  }

  const settingFinalCodeState = () => {
    let finalCodeValue = ''
    for (const key in code) {
      const currentKey = key as keyof typeof code
      finalCodeValue += code[currentKey]
    }
    setFinalCodeState(finalCodeValue)
  }

  useEffect(settingFinalCodeState, [code])
  useEffect(puttingTheInputsInFocus, [
    inputRefChar1,
    inputRefChar2,
    inputRefChar3,
    inputRefChar4,
    code
  ])

  return (
    <S.ConfirmationCodeInputWrapper>
      <S.InputsWrapper>
        <S.InputElement
          ref={inputRefChar1}
          maxLength={1}
          value={code.char1}
          placeholder="4"
          name={codePositions[0]}
          id={codePositions[0]}
          onBlur={handleOnBlur}
          onChange={handleChangeConfirmationCodeValue}
        />
        <S.InputElement
          ref={inputRefChar2}
          maxLength={1}
          placeholder="A"
          value={code.char2}
          name={codePositions[1]}
          id={codePositions[1]}
          onBlur={handleOnBlur}
          onChange={handleChangeConfirmationCodeValue}
        />
        <S.InputElement
          ref={inputRefChar3}
          maxLength={1}
          placeholder="K"
          value={code.char3}
          name={codePositions[2]}
          id={codePositions[2]}
          onBlur={handleOnBlur}
          onChange={handleChangeConfirmationCodeValue}
        />
        <S.InputElement
          ref={inputRefChar4}
          maxLength={1}
          placeholder="7"
          value={code.char4}
          name={codePositions[3]}
          id={codePositions[3]}
          onBlur={handleOnBlur}
          onChange={handleChangeConfirmationCodeValue}
        />
      </S.InputsWrapper>

      {showInvalidCodeError && <S.ErrorMessage>Código inválido</S.ErrorMessage>}

      {!showInvalidCodeError &&
      !showInvalidCodeMessage &&
      finalCodeState.length === 4 ? (
        <span className="info">
          Clique em continuar para confirmar o seu e-mail
        </span>
      ) : (
        !showInvalidCodeError && (
          <span className="info">Digite o código recebido</span>
        )
      )}
    </S.ConfirmationCodeInputWrapper>
  )
}

export default ConfirmationCodeInput
