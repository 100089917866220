import React, { createContext, useState } from 'react'
import { toggleHelpModalAction } from './actions'

import { IHelpModalContextProps, IHelpModalProviderProps } from './types'

const INITIAL_STATE: IHelpModalContextProps = {
  helpModalIsActive: false
}

export const helpModalContext =
  createContext<IHelpModalContextProps>(INITIAL_STATE)

const HelpModalPropsProvider = ({ children }: IHelpModalProviderProps) => {
  const [helpModalIsActive, setHelpModalIsActive] = useState<boolean>(false)

  const toggleHelpModal = () => {
    setHelpModalIsActive(toggleHelpModalAction(helpModalIsActive))
  }

  const value: IHelpModalContextProps = {
    helpModalIsActive,
    toggleHelpModal
  }

  return (
    <helpModalContext.Provider value={value}>
      {children}
    </helpModalContext.Provider>
  )
}

export default HelpModalPropsProvider
