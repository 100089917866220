import styled from 'styled-components'
import { themeColors } from '../../styles/theme.colors'
import Modal from 'react-modal'

export const NewBuyTemplateWrapper = styled.section`
  width: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

export const NewBuyPageTitle = styled.span`
  font-size: 2.2rem;
  color: ${themeColors.black};
  display: inline-block;
  margin-bottom: 35px;
  text-align: center;
`

export const OrText = styled(NewBuyPageTitle)`
  margin: 35px 0;
`

export const CustomerListWrapper = styled.ul`
  min-height: 100%;
  width: 100%;
  margin-top: 30px;
  justify-self: flex-start;
`

export const CustomerItem = styled.li`
  margin-bottom: 24px;
`

export const ConfirmButtonWrapper = styled.section`
  width: 100%;

  position: fixed;
  z-index: 0;
  bottom: 0;
  text-align: center;
  padding: 40px 0;

  button {
    max-width: 95%;
  }
`

export const ContinueWithStandbyPurchasesButton = styled.section`
  margin-top: 24px;
`

export const StandbyPurchasesTitle = styled.span`
  text-align: center;
  display: inline-block;
  font-weight: 500;
  font-size: 2.2rem;
  margin-bottom: 24px;
  color: ${themeColors.black};
`

export const CustomerNotFoundText = styled(StandbyPurchasesTitle)``

export const StandbyPurchasesWrapper = styled.ul`
  width: 100%;
  justify-self: flex-start;
`

export const StandbyPurchaseItem = styled(CustomerItem)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const RemoveStandbyPurchaseItem = styled.div`
  cursor: pointer;
  font-size: 1.2rem;
  &:hover {
    color: ${themeColors.primary_color};
  }
`

export const StandbyMessage = styled.p`
  font-size: 16px;
  color: #676767;
`

export const CustomModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  width: 90%;
  max-width: 300px;
  z-index: 9999;
`

export const ModalTitle = styled.p`
  font-size: 2rem;
  color: ${themeColors.black};
  display: inline-block;
  margin-bottom: 35px;
  text-align: center;
`

export const ModalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: 'Brandon Regular';
`

export const ConfirmButton = styled.button`
  padding: 16px;
  font-size: 1.5rem;
  font-family: 'Brandon Regular';
  cursor: pointer;
  min-width: 115px;
  border-radius: 45px;
  text-transform: uppercase;
  transition: all ease-in-out 300ms;
  border: none;
  color: ${themeColors.white};
  background-color: ${themeColors.secondary_color};
`

export const CancelButton = styled.button`
  padding: 16px;
  font-size: 1.5rem;
  font-family: 'Brandon Regular';
  cursor: pointer;
  min-width: 115px;
  border-radius: 45px;
  text-transform: uppercase;
  transition: all ease-in-out 300ms;
  color: ${themeColors.secondary_color};
  background-color: ${themeColors.white};
  border: 1px solid ${themeColors.secondary_color};
`
