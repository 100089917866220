import { useState } from 'react'

export const useLoading = () => {
  const [isLoading, setIsLoading] = useState(false)

  function startLoading() {
    setIsLoading(true)
  }

  function stopLoading() {
    setIsLoading(false)
  }

  return { isLoading, startLoading, stopLoading }
}
