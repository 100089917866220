import React from 'react'

import { BalanceTemplate, PageTemplate } from '../../templates'

const Balance: React.FC = () => {
  return (
    <PageTemplate>
      <BalanceTemplate />
    </PageTemplate>
  )
}

export default Balance
