import React, { Fragment, useContext } from 'react'

import LogoComponent from '../LogoComponent'

import { userContext } from '../../contexts/User/userContext'
import { helpModalContext } from '../../contexts/ContextOfTheHelpModal/HelpModal'
import { tokenAccessContext } from '../../contexts/TokenAccess/tokenAccessContext'
import { selectedCustomerContext } from '../../contexts/SelectedCustomer/selectedCustomer'
import { useCartContext } from '../../contexts/CartProvider/useCartContext'
// import { homeTutorialContext } from '../../contexts/HomeTutorial/homeTutorial'

import QuestionIconAsset from '../../assets/images/question_mark_icon.svg'
import ExitIconAsset from '../../assets/images/exit_icon.svg'

import * as S from './styles'

const Header: React.FC = () => {
  const { resetUser } = useContext(userContext)
  const { resetToken } = useContext(tokenAccessContext)
  const { actions: CartContextActions } = useCartContext()
  const { resetSelectedCustomer } = useContext(selectedCustomerContext)
  const { toggleHelpModal } = useContext(helpModalContext)
  // const { resetStateOfHomeTutorial } = useContext(homeTutorialContext)

  const logoutFn = () => {
    CartContextActions?.clearCartAction()
    resetSelectedCustomer?.()
    resetUser?.()
    resetToken?.()
    localStorage.removeItem('userEmail')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('user')

    // resetStateOfHomeTutorial?.()
  }

  const showModalWithHelpActions = () => {
    toggleHelpModal?.()
  }

  return (
    <Fragment>
      <S.HeaderWrapper>
        <LogoComponent size="SMALL" />
        <S.NavWrapper>
          <S.ActionsWrapper>
            <S.ActionItem onClick={showModalWithHelpActions}>
              <img src={QuestionIconAsset} alt="Ícone de interrogação" />
            </S.ActionItem>
            <S.ActionItem onClick={logoutFn}>
              <img src={ExitIconAsset} alt="Ícone de logout" />
              Sair
            </S.ActionItem>
          </S.ActionsWrapper>
        </S.NavWrapper>
      </S.HeaderWrapper>
    </Fragment>
  )
}

export default Header
