import { BoxMessageWrapper } from './styles'

export enum BoxSizesEnum {
  LITTLE = 'LITTLE',
  HIGH = 'HIGH',
  WIDE = 'WIDE'
}

export interface IBoxMessageComponentProps
  extends React.ComponentProps<typeof BoxMessageWrapper> {
  message: string
  size?: BoxSizesEnum
}

export interface IStyleProps {
  size?: BoxSizesEnum
}
