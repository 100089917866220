import { IStandByPurchaseSave } from '../interfaces'
import { showErrorToast } from '../utils'
import { axiosInstance } from './api'

export async function standByPurchaseSave(
  authToken: string,
  requestBody: IStandByPurchaseSave
) {
  try {
    const response = await axiosInstance.post('/standby', requestBody, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      }
    })
    return response.data
  } catch (err) {
    console.error(err)
    showErrorToast(
      'Algo deu errado ao salvar a compra em standby. Por favor, tente novamente!'
    )
  }
}

export async function standByPurchaseGet(authToken: string, shopper: number) {
  try {
    const response = await axiosInstance.get(`standby?shopper=${shopper}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      }
    })
    return response.data
  } catch (err) {
    console.error(err)
    showErrorToast(
      'Algo deu errado ao buscar as compras em standby. Por favor, tente novamente!'
    )
  }
}

export async function standByPurchaseRemove(
  authToken: string,
  id: string,
  shopper: number
) {
  try {
    const response = await axiosInstance.delete(`/standby`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      },
      data: {
        shopper: shopper,
        purchase: id
      }
    })
    return response.data
  } catch (err) {
    console.error(err)
    showErrorToast(
      'Algo deu errado ao remover a compra em standby. Por favor, tente novamente!'
    )
  }
}
