import React, { useState, useEffect, useContext, createContext } from 'react'

import { IToken } from '../../interfaces'
import { verifyAccessToken } from '../../services'
import { userContext } from '../User/userContext'
import { addTokenAction, clearTokenAccessAction } from './actions'
import { ITokenAccessContextType, ITokenContextProviderProps } from './types'

const INITIAL_STATE = {
  token: {
    accessToken: '',
    tokenType: ''
  }
}

export const tokenAccessContext =
  createContext<ITokenAccessContextType>(INITIAL_STATE)

const TokenAccesssContextProvider = ({
  children
}: ITokenContextProviderProps) => {
  const { user, addUser } = useContext(userContext)
  const [token, setToken] = useState<IToken>({
    tokenType: '',
    accessToken: ''
  })

  const addToken = (token: IToken) => setToken(addTokenAction(token))
  const resetToken = () => {
    window.sessionStorage.removeItem('user_session')
    setToken(clearTokenAccessAction())
  }

  const verifyUserSession = () => {
    ;(async () => {
      const userSessionData = window.sessionStorage.getItem('user_session')
      if (userSessionData !== null) {
        const response = await verifyAccessToken(
          userSessionData,
          String(user?.email)
        )

        if (response && !response.success) {
          setToken({ accessToken: '', tokenType: '' })
          addUser?.({ email: '' })
        } else {
          setToken({ accessToken: userSessionData, tokenType: 'Bearer' })
          addUser?.({ email: response.email })
        }
      }
    })()
  }

  const savingTokenInSessionStorage = () => {
    const dataOfTheLocal = window.sessionStorage.getItem('user_session')
    if (!token && !dataOfTheLocal) {
      window.sessionStorage.setItem('user_session', '')
      return
    }
    if (token.accessToken) {
      window.sessionStorage.setItem('user_session', token.accessToken)
    }
  }

  const fillingDataWithDatasAlreadySavedInLocalstorage = () => {
    const dataLocal = window.sessionStorage.getItem('user_session')
    if (dataLocal) {
      setToken({ accessToken: dataLocal, tokenType: 'Bearer' })
    }
  }

  useEffect(fillingDataWithDatasAlreadySavedInLocalstorage, [])
  useEffect(savingTokenInSessionStorage, [token])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(verifyUserSession, [])

  const value: ITokenAccessContextType = { token, addToken, resetToken }

  return (
    <tokenAccessContext.Provider value={value}>
      {children}
    </tokenAccessContext.Provider>
  )
}

export default TokenAccesssContextProvider
