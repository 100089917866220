import React from 'react'
import Switch from 'react-switch'

import { IToggleSwitchComponentProps } from './types'
import { themeColors } from '../../styles/theme.colors'

import * as S from './styles'

const ToggleSwitchComponent: React.FC<IToggleSwitchComponentProps> = ({
  checkedState,
  setCheckedState
}) => {
  const handleChange = (checked: boolean) => setCheckedState(checked)

  return (
    <S.SwitchWrapper>
      <Switch
        width={55}
        height={25}
        checkedIcon={false}
        uncheckedIcon={false}
        checked={checkedState}
        onChange={handleChange}
        className="switch-component"
        offColor={themeColors.white}
        onColor={themeColors.white}
      />
    </S.SwitchWrapper>
  )
}

export default ToggleSwitchComponent
