import React, {
  useMemo,
  useState,
  FormEvent,
  useCallback,
  ChangeEvent,
  useContext
} from 'react'
import { useNavigate } from 'react-router-dom'

import {
  ButtonComponent,
  InputComponent,
  ToggleTypeComponent
} from '../../components'
import { Container } from '../../layout'

import { useForm } from '../../hooks/useForm'
import { registerCustomer } from '../../services'
import { tokenAccessContext } from '../../contexts/TokenAccess/tokenAccessContext'

import { IInputDataItem } from './types'
import { CNPJMask, DOCUMENTMask, PHONEMask, showErrorToast } from '../../utils'
import { TypeAccountEnum, TypePersonEnum } from '../../interfaces'

import * as S from './styles'

const RegisterCustomerTemplate: React.FC = () => {
  const bankName = useForm('text')
  const agency = useForm('number')
  const account = useForm('number')
  const nameInput = useForm('text')
  const bankCode = useForm('number')
  const emailInput = useForm('email')
  const phoneInput = useForm('phone')
  const accountDigit = useForm('number')
  const pixKey = useForm('PIX')
  const documentInput = useForm('document')
  const documentCNPJInput = useForm('CNPJ')

  const [selectedTypePerson, setSelectedTypePerson] = useState<TypePersonEnum>(
    TypePersonEnum.PHYSICAL_PERSON
  )
  const [selectedTypeAccount, setSelectedTypeAccount] =
    useState<TypeAccountEnum>(TypeAccountEnum.TRANSFER)
  const { token } = useContext(tokenAccessContext)
  const navigate = useNavigate()

  const replaceChars = (value: string) => value.replace(/[.\-+()\s]+/g, '')

  const handleChangeSelectedTypePerson = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      if (
        value === TypePersonEnum.PHYSICAL_PERSON ||
        value === TypePersonEnum.LEGAL_PERSON
      ) {
        setSelectedTypePerson(value)
      }
    },
    []
  )
  const handleChangeSelectedTypeAccount = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      if (
        value === TypeAccountEnum.TRANSFER ||
        value === TypeAccountEnum.SAVINGS
      )
        setSelectedTypeAccount(value)
    },
    []
  )

  const personalDataInputs: IInputDataItem[] = useMemo(() => {
    return [
      {
        label: 'Nome Completo',
        id: 'name',
        name: 'name',
        inputProps: nameInput,
        placeholder: 'Digite aqui',
        value: nameInput.value,
        errorMessage: nameInput.errorMessage
      },
      {
        label: 'Documento (CPF ou Passaporte)',
        id: 'document',
        name: 'document',
        maxLength: '14',
        minLength: '8',
        inputProps: documentInput,
        placeholder: '000.000.000-00',
        value: DOCUMENTMask(documentInput.value).toUpperCase(),
        errorMessage: documentInput.errorMessage
      },
      {
        label: 'Email',
        id: 'email',
        name: 'email',
        inputProps: emailInput,
        placeholder: 'seuemail@dazroupaz.com.br',
        value: emailInput.value,
        errorMessage: emailInput.errorMessage
      },
      {
        label: 'Celular',
        id: 'phone',
        name: 'phone',
        maxLength: '15',
        inputProps: phoneInput,
        placeholder: '(00) 00000-0000',
        value: PHONEMask(phoneInput.value),
        errorMessage: phoneInput.errorMessage
      }
    ]
  }, [nameInput, documentInput, emailInput, phoneInput])

  const bankData: IInputDataItem[] = useMemo(() => {
    const arrayOfTheInputs: IInputDataItem[] = [
      {
        label: 'Tipo',
        typeComponent: 'select',
        valueTwo: TypePersonEnum.LEGAL_PERSON,
        valueOne: TypePersonEnum.PHYSICAL_PERSON,
        selectedValue: selectedTypePerson,
        handleChange: handleChangeSelectedTypePerson
      },
      {
        label: 'Código do Banco',
        id: 'bankCode',
        name: 'bankCode',
        inputProps: bankCode,
        placeholder: '000',
        value: bankCode.value,
        errorMessage: bankCode.errorMessage
      },
      {
        label: 'Nome do Banco',
        id: 'bankName',
        name: 'bankName',
        inputProps: bankName,
        placeholder: 'Nome do Banco S.A.',
        value: bankName.value,
        errorMessage: bankName.errorMessage
      },
      {
        label: 'Tipo de conta',
        typeComponent: 'select',
        valueTwo: TypeAccountEnum.TRANSFER,
        valueOne: TypeAccountEnum.SAVINGS,
        selectedValue: selectedTypeAccount,
        handleChange: handleChangeSelectedTypeAccount
      },
      {
        label: 'Agência',
        id: 'agency',
        name: 'agency',
        inputProps: agency,
        placeholder: '0000',
        value: agency.value,
        errorMessage: agency.errorMessage
      },
      {
        label: 'Conta',
        id: 'account',
        name: 'account',
        inputProps: account,
        placeholder: '000000',
        value: account.value,
        errorMessage: account.errorMessage
      },
      {
        label: 'Dígito da Conta',
        id: 'accountDigit',
        name: 'accountDigit',
        inputProps: accountDigit,
        placeholder: '00',
        value: accountDigit.value,
        errorMessage: accountDigit.errorMessage
      },
      {
        label: 'Chave PIX',
        id: 'pixKey',
        name: 'pixKey',
        inputProps: pixKey,
        placeholder: 'Digite aqui',
        value: pixKey.value,
        errorMessage: pixKey.errorMessage
      }
    ]

    if (selectedTypePerson === TypePersonEnum.LEGAL_PERSON) {
      arrayOfTheInputs.splice(1, 0, {
        label: 'CNPJ',
        id: 'documentCnpj',
        name: 'documentCnpj',
        maxLength: '18',
        inputProps: documentCNPJInput,
        placeholder: '00.000.000/0000-00',
        value: CNPJMask(documentCNPJInput.value),
        errorMessage: documentCNPJInput.errorMessage
      })
    }

    return arrayOfTheInputs
  }, [
    agency,
    account,
    bankName,
    bankCode,
    accountDigit,
    pixKey,
    documentCNPJInput,
    selectedTypePerson,
    selectedTypeAccount,
    handleChangeSelectedTypePerson,
    handleChangeSelectedTypeAccount
  ])

  const handleSubmitForm = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const personalValidations = personalDataInputs.map((data) =>
      data.inputProps?.validate()
    )
    const bankValidations = bankData.map((data) => data.inputProps?.validate())
    const validations = personalValidations.concat(bankValidations)

    const hasValidData = validations.every(
      (value) => value === true || value === undefined
    )

    if (!hasValidData) {
      showErrorToast('Verifique seus dados novamente')
      return
    }

    const data = {
      name: nameInput.value,
      document: replaceChars(documentInput.value),
      email: emailInput.value,
      phone: replaceChars(phoneInput.value),
      type: selectedTypePerson === TypePersonEnum.PHYSICAL_PERSON ? 'PF' : 'PJ',
      account: {
        type_account: 2,
        type_holder:
          selectedTypePerson === TypePersonEnum.PHYSICAL_PERSON ? 'PF' : 'PJ',
        document: documentCNPJInput.value
          ? replaceChars(documentCNPJInput.value)
          : replaceChars(documentInput.value),
        bank: bankName.value,
        name: nameInput.value,
        type: selectedTypeAccount === TypeAccountEnum.TRANSFER ? 'CC' : 'PO',
        ag: agency.value,
        cc: account.value,
        digit: accountDigit.value,
        pix_type: 4,
        pix_key: pixKey.value
      }
    }

    try {
      await registerCustomer(token.accessToken, data)

      navigate('/gender-size', { state: { email: emailInput.value } })
    } catch (error) {
      console.log(error)
    }
  }

  const renderInputsPersonalData = () => {
    return personalDataInputs.map((input) => (
      <S.InputContainer key={input.id}>
        <S.InputLabel htmlFor={input.id}>{input.label}</S.InputLabel>
        <InputComponent
          {...input.inputProps}
          id={input.id}
          name={input.id}
          value={input.value}
          maxLength={String(input.maxLength)}
          minLength={String(input.minLength)}
          placeholder={input.placeholder}
          errorMessage={String(input.errorMessage)}
        />
      </S.InputContainer>
    ))
  }

  const renderInpustBankData = () => {
    return bankData.map((input, index) => {
      if (input.typeComponent && input.handleChange) {
        return (
          <S.InputContainer key={`${input.label}-${index}`}>
            <S.InputLabel>{input.label}</S.InputLabel>
            <ToggleTypeComponent
              valueTwo={String(input.valueOne)}
              valueOne={String(input.valueTwo)}
              selectedValue={String(input.selectedValue)}
              handleChange={input.handleChange}
            />
          </S.InputContainer>
        )
      }

      if (input.inputProps) {
        return (
          <S.InputContainer key={input.id}>
            <S.InputLabel htmlFor={input.id}>{input.label}</S.InputLabel>
            <InputComponent
              {...input.inputProps}
              id={input.id}
              name={input.id}
              value={input.value}
              maxLength={String(input.maxLength)}
              placeholder={input.placeholder}
              errorMessage={String(input.errorMessage)}
            />
          </S.InputContainer>
        )
      }
    })
  }

  return (
    <Container>
      <S.RegisterCustomerWrapper onSubmit={handleSubmitForm}>
        <S.PersonalDataWrapper>
          {renderInputsPersonalData()}
        </S.PersonalDataWrapper>
        <S.BankDataWrapper>
          <S.BankTitleWrapper>
            <S.BankDataTitle>Dados bancários</S.BankDataTitle>
          </S.BankTitleWrapper>
          {renderInpustBankData()}
        </S.BankDataWrapper>
        <ButtonComponent type="submit">Confirmar</ButtonComponent>
      </S.RegisterCustomerWrapper>
    </Container>
  )
}

export default RegisterCustomerTemplate
