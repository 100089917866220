export interface ICustomer {
  id: string
  name: string
  email: string
  phone?: string
  document?: string
  cnpj?: string
  bankCode?: number
  bankName?: string
  agency?: number
  account?: number
  accountDigit?: number
  pixKey?: string
  typePerson?: TypePersonEnum
  typeAccount?: TypeAccountEnum
}

export enum TypePersonEnum {
  PHYSICAL_PERSON = 'Pessoa Fisíca',
  LEGAL_PERSON = 'Pessoa Jurídica'
}

export enum TypeAccountEnum {
  TRANSFER = 'Transferência',
  SAVINGS = 'Poupança'
}

export interface IRegisterCustomer {
  name: string
  document: string
  email: string
  phone: string
  type: string
  account: {
    type_account: number
    type_holder: string
    document?: string
    bank: string
    name: string
    type: string
    ag: string
    cc: string
    digit: string
    pix_type: number
    pix_key: string
  }
}
