import React, { Fragment } from 'react'

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import { useIsLogged } from '../hooks/useIsLogged'

import {
  Home,
  Cart,
  Login,
  NewBuy,
  ProductGenderSize,
  Brands,
  Categories,
  ProductQuality,
  RegisterCustomer,
  OrderQuestionnaire,
  PurchaseComplete,
  CompletePurchases,
  CompletePurchaseDetail,
  Balance
} from '../pages'

import { routesNameEnum } from './routesNameEnum'

const AppRoutes = () => {
  const { isLogged } = useIsLogged()
  return (
    <BrowserRouter>
      <Routes>
        {!isLogged ? (
          <Fragment>
            <Route path="*" element={<Navigate to={routesNameEnum.LOGIN} />} />
            <Route path={routesNameEnum.LOGIN} element={<Login />} />
          </Fragment>
        ) : (
          <Fragment>
            <Route path="*" element={<Navigate to={routesNameEnum.HOME} />} />
            <Route path={routesNameEnum.HOME} element={<Home />} />
            <Route path={routesNameEnum.NEW_BUY} element={<NewBuy />} />
            <Route
              path={routesNameEnum.REGISTER_CUSTOMER}
              element={<RegisterCustomer />}
            />
            <Route
              path={routesNameEnum.PRODUCT_GENDER_SIZE}
              element={<ProductGenderSize />}
            />
            <Route path={routesNameEnum.CATEGORIES} element={<Categories />} />
            <Route
              path={`${routesNameEnum.SELECT_BRAND}/:categoryID`}
              element={<Brands />}
            />
            <Route
              path={routesNameEnum.CHOICE_OF_PRODUCT_QUALITY}
              element={<ProductQuality />}
            />
            <Route path={routesNameEnum.CART} element={<Cart />} />
            <Route
              path={routesNameEnum.ORDER_QUESTIONNAIRE}
              element={<OrderQuestionnaire />}
            />
            <Route
              path={routesNameEnum.SHARE_PURCHASE}
              element={<PurchaseComplete />}
            />
            <Route
              path={routesNameEnum.COMPLETE_PURCHASES}
              element={<CompletePurchases />}
            />
            <Route
              path={`${routesNameEnum.COMPLETE_PURCHASE_DETAIL}/:purchaseID`}
              element={<CompletePurchaseDetail />}
            />
            <Route path={routesNameEnum.BALANCE} element={<Balance />} />
          </Fragment>
        )}
      </Routes>
    </BrowserRouter>
  )
}

export default AppRoutes
