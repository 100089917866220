import styled from 'styled-components'

export const ProductGenderSizeWrapper = styled.section`
  width: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

export const SelectSection = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`

export const SelectWrapperTop = styled.div`
  width: 100%;
  padding: 0 34px;
  margin-bottom: 34px;
  position: relative;
  z-index: 2;
`

export const SelectWrapperBottom = styled.div`
  width: 100%;
  padding: 0 34px;
  margin-bottom: 34px;
  z-index: 1;
`

export const Label = styled.p`
  font-weight: 390;
  font-size: 22px;
  line-height: 31px;
  margin-bottom: 35px;
`

export const ButtonWrapper = styled.div`
  position: fixed;
  z-index: 0;
  bottom: 0;
  text-align: center;
  padding: 40px 0;
  width: 100%;

  button {
    max-width: 95%;
  }
`
