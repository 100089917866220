import styled, { css } from 'styled-components'
import { IStyleProps } from './types'
import { themeColors } from '../../styles/theme.colors'

export const ProductSelectComponentWrapper = styled.label<IStyleProps>`
  width: 100%;
  max-width: 399px;
  padding: 12px 20px;

  gap: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: all ease-in-out 50ms;

  ${({ selected }) =>
    selected &&
    css`
      border-radius: 17px;
      border: 4px solid ${themeColors.tertiary_color};
    `}

  input[type="radio"] {
    display: none;
  }
`

export const ProductImage = styled.img`
  max-width: 120px;
`

export const ProductDescriptionWrapper = styled.div``

export const ProductDescription = styled.p`
  font-size: 1.8rem;
  margin-bottom: 5px;
  line-height: 95%;
  color: ${themeColors.black};
`

export const ProductPrice = styled(ProductDescription)``
