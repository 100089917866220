import React from 'react'

import { PageTemplate, ProductGenderSizeTemplate } from '../../templates'

const ProductGenderSize: React.FC = () => {
  return (
    <PageTemplate>
      <ProductGenderSizeTemplate />
    </PageTemplate>
  )
}

export default ProductGenderSize
