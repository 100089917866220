import styled from 'styled-components'
import { themeColors } from '../../styles/theme.colors'
import { IStyleProps } from './types'

export const ReasonLabelWrapper = styled.label`
  gap: 15px;
  display: flex;
  align-items: center;
`

export const ReasonInputWrapper = styled.div<IStyleProps>`
  width: 48px;
  height: 48px;

  background-color: ${({ thisReasonIsChecked }) =>
    thisReasonIsChecked ? themeColors.primary_color : 'trasparent'};

  border: 1px solid ${themeColors.primary_color};

  > input[type='checkbox'] {
    display: none;
  }
`

export const ReasonInput = styled.input``

export const ReasonText = styled.span`
  font-size: 1.8rem;
  color: ${themeColors.black};
`
