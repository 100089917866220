import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import LogoAsset from '../../assets/images/logo_daz_roupaz.png'
import { routesNameEnum } from '../../routes/routesNameEnum'

import { ILogoComponentProps } from './types'

import * as S from './styles'

const LogoComponent: React.FC<ILogoComponentProps> = ({ size = 'LARGE' }) => {
  return (
    <Fragment>
      <Link to={routesNameEnum.HOME}>
        <S.LogoImage imageSize={size} src={LogoAsset} alt="Logo Daz Shopper" />
      </Link>
    </Fragment>
  )
}

export default LogoComponent
