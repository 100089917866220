import React, { Fragment, useContext } from 'react'

import { helpModalContext } from '../../contexts/ContextOfTheHelpModal/HelpModal'

import ModalComponent from '../ModalComponent'

import { ReactComponent as EmailIcon } from '../../assets/images/email-icon.svg'
import { ReactComponent as WhatsappIcon } from '../../assets/images/whatsapp-icon.svg'

import * as S from './styles'

const HelpModalComponent: React.FC = () => {
  const { helpModalIsActive, toggleHelpModal } = useContext(helpModalContext)

  // TODO: switch to the correct information in PROD
  const helpEmail = `shopper@dazroupaz.com.br`
  const helpPhoneFormatted = `+55 (11) 4933-8511`
  const helpPhoneParam = `+551149338511`

  return (
    <Fragment>
      {toggleHelpModal && (
        <ModalComponent
          withBackground
          onClose={toggleHelpModal}
          isActive={helpModalIsActive}
        >
          <S.HelpModalWrapper>
            <a href={`mailto:${helpEmail}`}>
              <S.HelpLinkWrapper>
                <EmailIcon />
                <div>
                  <S.HelpLinkTitle>Mande uma mensagem</S.HelpLinkTitle>
                  <S.HelpLinkDescription>{helpEmail}</S.HelpLinkDescription>
                </div>
              </S.HelpLinkWrapper>
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://web.whatsapp.com/send?phone=${helpPhoneParam}`}
            >
              <S.HelpLinkWrapper>
                <WhatsappIcon />
                <div>
                  <S.HelpLinkTitle>Fale Conosco</S.HelpLinkTitle>
                  <S.HelpLinkDescription>
                    Whats:{helpPhoneFormatted}
                  </S.HelpLinkDescription>
                </div>
              </S.HelpLinkWrapper>
            </a>
          </S.HelpModalWrapper>
        </ModalComponent>
      )}
    </Fragment>
  )
}

export default HelpModalComponent
