import styled, { keyframes } from 'styled-components'
import { themeColors } from '../../styles/theme.colors'

export const animationEntry = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -10px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`

export const ConfirmationCodeInputWrapper = styled.div`
  .info {
    display: block;
    font-size: 18px;
    text-align: center;
    color: ${themeColors.black};
    margin-top: 24px;
  }
`

export const InputsWrapper = styled.div`
  width: 300px;
  margin: 0 auto;
  display: flex;
  gap: 35px;
  align-items: center;
`

export const InputElement = styled.input`
  border: none;
  outline: none;
  text-align: center;

  font-size: 51px;
  text-transform: uppercase;

  width: 100%;
  max-width: 50px;

  border-bottom: 4px solid ${themeColors.secondary_color};

  &::placeholder {
    color: ${themeColors.grey};
  }
`

export const ErrorMessage = styled.p`
  display: block;
  font-size: 18px;
  margin-top: 24px;
  text-align: center;
  color: ${themeColors.error_color};
  animation: ${animationEntry} forwards ease-in-out 0.5s;
`
