import styled from 'styled-components'
import { themeColors } from '../../styles/theme.colors'

export const CompletePurchasesWrapper = styled.section``

export const PurchasesHeading = styled.h1`
  text-align: center;
`

export const CardWithDateOfThePurchase = styled.div`
  padding: 8px 20px;
  min-width: 110px;
  max-width: 110px;
  min-height: 110px;
  max-height: 110px;
  border: 1px solid ${themeColors.primary_color};

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

export const PurchaseDataWrapper = styled.button`
  gap: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  border: none;
  background-color: transparent;
  cursor: pointer;

  .infos {
    text-align: left;
  }
`

export const QuantityOfPieces = styled.span`
  display: block;
  font-size: 1.8rem;
  line-height: 30px;
  color: ${themeColors.black};
`

export const PurChaseCodeText = styled.span`
  display: block;
  font-size: 2.2rem;
  line-height: 30px;
  color: ${themeColors.grey_input_color};
`

export const CustomerInfoText = styled.span`
  display: block;
  font-size: 2rem;
  line-height: 30px;
  color: ${themeColors.black};
  max-height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const DayThePurchaseText = styled.span`
  font-size: 4rem;
  display: inline-block;
  color: ${themeColors.black};
`

export const MonthThePurchaseText = styled.span`
  font-size: 2.2rem;
  display: inline-block;
  color: ${themeColors.black};
`

export const ScreenTitle = styled.span`
  font-size: 2.2rem;
  color: ${themeColors.black};
  display: inline-block;
  margin-bottom: 30px;
  text-align: center;
`

export const NotFound = styled.span`
  font-size: 1.5rem;
  color: ${themeColors.black};
  display: block;
  text-align: center;
`
