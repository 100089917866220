import styled from 'styled-components'
import { themeColors } from '../../styles/theme.colors'
import { IChangeComponentColor, IStyleProps } from './types'

export const Wrapper = styled.section`
  width: 100%;
  max-width: 300px;
  position: relative;
`

export const SelectComponentWrapper = styled.section<IChangeComponentColor>`
  width: 100%;
  min-height: 50px;

  padding: 11px 19px;
  border-radius: 45px;

  border: 1px solid ${(p) => p.color};
`

export const SelectedOptionAndButtonWrapper = styled.section`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const SelectedOptionLabel = styled.label<IChangeComponentColor>`
  font-size: 2rem;
  text-transform: uppercase;
  color: ${(p) => p.color};
`

export const SearchInput = styled.input`
  border: none;
  height: 28px;
  max-width: 200px;
  outline: none;
  font-size: 20px;
`

export const DownTriangleElement = styled.div<IChangeComponentColor>`
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 20px solid ${(p) => p.color};
`

export const UpTriangleElement = styled.div<IChangeComponentColor>`
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 20px solid ${(p) => p.color};
`

export const ShowOptionsButton = styled.button`
  border: none;
  background-color: transparent;
`

export const OptionsWrapper = styled.section<IChangeComponentColor>`
  width: 100%;
  margin-top: 35px;
  max-height: 184px;
  overflow-y: auto;
  position: absolute;
  top: 22px;
  background-color: ${themeColors.white};
  border: 1px solid ${(p) => p.color};
`

export const SelectOptionLabel = styled.label<IStyleProps>`
  display: block;
  padding: 12px;
  font-size: 1.6rem;

  color: ${({ optionSelected }) =>
    optionSelected ? themeColors.white : themeColors.black};

  background-color: ${({ optionSelected }) =>
    optionSelected ? themeColors.toggle_offColor : themeColors.white};

  &:hover {
    color: ${themeColors.white};
    background-color: ${(p) => p.color};
  }

  > input[type='radio'] {
    display: none;
  }
`
export const Overlay = styled.div`
  max-width: 100%;
  max-height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0.5;
  background: transparent;
`
