import { IGenderOptions } from '../interfaces/genderSize'

export const genderOptions: IGenderOptions[] = [
  { label: 'Masculino', value: '1' },
  { label: 'Feminino', value: '2' },
  { label: 'inf masc', value: '4' },
  { label: 'inf fem', value: '5' },
  { label: 'pets', value: '7' },
  { label: 'unissex', value: '8' }
]
