import React from 'react'

import { PageTemplate, CompletePurchaseDetailTemplate } from '../../templates'

const CompletePurchaseDetail: React.FC = () => {
  return (
    <PageTemplate>
      <CompletePurchaseDetailTemplate />
    </PageTemplate>
  )
}

export default CompletePurchaseDetail
