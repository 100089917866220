import styled from 'styled-components'
import { themeColors } from '../../styles/theme.colors'

export const CustomerInforLabelElement = styled.label`
  font-size: 1.4rem;
  margin-bottom: 14px;
  margin-left: 16px;
  display: inline-block;
  color: ${themeColors.grey_3};
`
