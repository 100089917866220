import { ICartItem, IDispatchFn } from '../../interfaces'

import { CART_ACTIONS_TYPES } from './cart.actions-types'

const updateCartItem = (
  cartItems: ICartItem[],
  cartItemToUpdate: ICartItem,
  toggleValue: boolean
): ICartItem[] => {
  const currentItemsInTheCart = [...cartItems]

  const existingCartItemIndex = cartItems.findIndex(
    (cartItem) => cartItem.cartItemID === cartItemToUpdate.cartItemID
  )

  if (existingCartItemIndex !== -1) {
    const currentItemInCart = currentItemsInTheCart[existingCartItemIndex]

    const cartItemUpdated: ICartItem = {
      ...currentItemInCart,
      itemEnabledForPurchase: toggleValue
    }
    currentItemsInTheCart.splice(existingCartItemIndex, 1, cartItemUpdated)

    return currentItemsInTheCart
  }

  return currentItemsInTheCart
}

const clearCart = () => {
  window.localStorage.removeItem('cartItems')
  return []
}

export function buildActions(dispatch: IDispatchFn) {
  const addItemToCartAction = (payload: ICartItem) => {
    dispatch({
      type: CART_ACTIONS_TYPES.ADD_ITEM_TO_CART,
      payload: payload
    })
  }

  const updateCartItemAction = (
    cartItems: ICartItem[],
    cartItemToUpdate: ICartItem,
    toggleValue: boolean
  ) => {
    dispatch({
      type: CART_ACTIONS_TYPES.UPDATE_ITEM_OF_THE_CART,
      payload: updateCartItem(cartItems, cartItemToUpdate, toggleValue)
    })
  }

  const clearCartAction = () => {
    dispatch({
      type: CART_ACTIONS_TYPES.CLEAR_ALL_ITEMS_OF_THE_CART,
      payload: clearCart()
    })
  }

  return {
    addItemToCartAction,
    updateCartItemAction,
    clearCartAction
  }
}
