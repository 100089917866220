import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useCallback,
  ChangeEvent
} from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import {
  ButtonComponent,
  ModalComponent,
  CustomerInfoLabel,
  ProductSelectComponent,
  CategorySelectComponent,
  LoadingComponent
} from '../../components'
import { Container, LoadingContainer } from '../../layout'

import { useCartContext } from '../../contexts/CartProvider/useCartContext'
import { selectedCustomerContext } from '../../contexts/SelectedCustomer/selectedCustomer'
import { tokenAccessContext } from '../../contexts/TokenAccess/tokenAccessContext'
import { selectedGenderSizeContext } from '../../contexts/SelectedGenderSize/selectedGenderSize'

import { routesNameEnum } from '../../routes/routesNameEnum'
import { IBrand, ICategorie, IProductQuality } from '../../interfaces'

import { useLoading } from '../../hooks'
import { fetchQuality } from '../../services/purchase.service'

import { randomStringIDGenerator } from '../../utils/random-string-id-generator'
import { DAZ_DEFAULT_ICON, showErrorToast } from '../../utils'

import * as S from './styles'

const ProductQualityTemplate: React.FC = () => {
  const { token } = useContext(tokenAccessContext)
  const navigate = useNavigate()
  const { state } = useLocation()
  const { isLoading, startLoading, stopLoading } = useLoading()

  const { category: categorySelected, brand: brandSelected } = state as {
    category: ICategorie
    brand: IBrand
  }

  const { actions: CartContextActions } = useCartContext()
  const { customer } = useContext(selectedCustomerContext)
  const { genderSize } = useContext(selectedGenderSizeContext)

  const [selectedTypeProduct, setSelectedTypeProduct] = useState('')
  const [typesOfTheProductsOptions, setTypesOfTheProductsOptions] = useState<
    IProductQuality[]
  >([])
  const [showProducts, setShowProducts] = useState(false)

  const [priceIndex, setpriceIndex] = useState('')

  const [showModalWithTags, setShowModalWithTags] = useState(false)

  const handleChangeSelectedTypeProduct = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      setSelectedTypeProduct(value)

      const selectedProduct = typesOfTheProductsOptions.find(
        (item) => item.type === value
      )
      if (selectedProduct) {
        const index = typesOfTheProductsOptions.indexOf(selectedProduct)
        setpriceIndex(`Preço ${index + 1}`)
      }
    },
    [typesOfTheProductsOptions]
  )

  const getAllProductsTypes = () => {
    ;(async () => {
      try {
        startLoading()
        const response = await fetchQuality(
          token.accessToken,
          categorySelected.id,
          brandSelected.id,
          `${genderSize?.gender?.value}`,
          `${genderSize?.size?.value}`
        )
        if (response?.success) {
          const levelsData = response.levels.map((level: IProductQuality) => {
            return {
              ...level,
              image: level.image || DAZ_DEFAULT_ICON
            }
          })
          // setLevelsData(levelsData)
          setTypesOfTheProductsOptions(levelsData)
          setShowProducts(true)
        }
      } catch (err) {
        console.log(err)
      } finally {
        stopLoading()
      }
    })()
  }

  const handleAddItemToCart = () => {
    if (selectedTypeProduct) {
      const selectedProduct = typesOfTheProductsOptions.find(
        (item) => item.type === selectedTypeProduct
      )
      if (!selectedProduct) return
      if (!selectedProduct.cost || selectedProduct.cost === 0) {
        showErrorToast('Qualidade não disponível para esta combinação')
        return
      }
      CartContextActions?.addItemToCartAction?.({
        ...{
          id: selectedProduct.type,
          price: selectedProduct.payout.toFixed(2),
          dazPrice: selectedProduct.cost.toFixed(2),
          cartItemID: randomStringIDGenerator(),
          description: `${brandSelected.name} ${categorySelected.name} ${priceIndex}`,
          productImage: selectedProduct.image,
          itemEnabledForPurchase: true,
          brandId: brandSelected.id,
          categoryId: categorySelected.id,
          gender: `${genderSize?.gender?.value}`,
          size: `${genderSize?.size?.value}`
        }
      })
      navigate(routesNameEnum.CART)
    }
  }

  const handleShowModalWithTags = () => setShowModalWithTags(!showModalWithTags)
  const handleCloseModal = () => setShowModalWithTags(!showModalWithTags)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getAllProductsTypes, [token])

  return (
    <Fragment>
      <CustomerInfoLabel
        label={`Cliente: ${customer?.email ? customer?.email : customer?.name}`}
      />
      <Container>
        <S.ProductQualityWrapper>
          <S.LinkWrapper>Clique para trocar</S.LinkWrapper>
          <S.BrandAndCategoryWrapper>
            <Link
              style={{ display: 'block', marginBottom: '8px' }}
              to={routesNameEnum.CATEGORIES}
            >
              <CategorySelectComponent
                label={categorySelected.name}
                icon={String(categorySelected.image)}
              />
            </Link>
            <Link to={`${routesNameEnum.CATEGORIES}/${categorySelected.id}`}>
              <CategorySelectComponent
                label={brandSelected.name}
                icon={String(categorySelected.image)}
              />
            </Link>
          </S.BrandAndCategoryWrapper>
          <S.ButtonWrapper>
            <ButtonComponent
              onClick={handleShowModalWithTags}
              formatType="RECTANGULAR"
            >
              Ver etiquetas que não compramos
            </ButtonComponent>
          </S.ButtonWrapper>

          {!isLoading ? (
            showProducts ? (
              <S.ProductsContainer>
                <S.ScreenTitle>Qual tipo é mais similar?</S.ScreenTitle>
                <S.ProductsWrapper>
                  {typesOfTheProductsOptions.map((item, index) => (
                    <S.ProductItem key={`${item.type}-${index}`}>
                      <ProductSelectComponent
                        option={item.type}
                        label={`Preço ${index + 1}`}
                        dazPrice={Number(item.cost)}
                        productImage={item.image}
                        customerPrice={Number(item.cost)}
                        selectedProductType={selectedTypeProduct}
                        handleChangeSelectProductType={
                          handleChangeSelectedTypeProduct
                        }
                      />
                    </S.ProductItem>
                  ))}
                </S.ProductsWrapper>
                <S.ButtonConfirmWrapper>
                  <ButtonComponent onClick={handleAddItemToCart}>
                    Cadastrar
                  </ButtonComponent>
                </S.ButtonConfirmWrapper>
              </S.ProductsContainer>
            ) : (
              <S.ScreenTitle>Sem dados para essa combinação</S.ScreenTitle>
            )
          ) : (
            <LoadingContainer>
              <LoadingComponent />
            </LoadingContainer>
          )}
        </S.ProductQualityWrapper>
      </Container>
      <ModalComponent
        isActive={showModalWithTags}
        onClose={handleCloseModal}
        titleModal="Não compramos"
      >
        {/* TODO: add the tags coming from the api */}
        {brandSelected.etiquetaNaoPermitida ? (
          <S.TagImage src={brandSelected.etiquetaNaoPermitida} alt="Image" />
        ) : (
          <S.ScreenTitle>Sem restrições para esta combinação</S.ScreenTitle>
        )}
      </ModalComponent>
    </Fragment>
  )
}

export default ProductQualityTemplate
