import styled from 'styled-components'
import { themeColors } from '../../styles/theme.colors'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 24px;
`

export const InputComponent = styled.input`
  display: flex;
  max-width: 80px;
  height: 48px;
  text-align: center;
  font-size: 3.2rem;
  border-radius: 45px;
  border: 1px solid ${themeColors.secondary_color};
  margin: 0 24px;
`

export const IncreaseButton = styled.button`
  width: 48px;
  height: 48px;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1.8rem;
  color: ${themeColors.white};
  background-color: ${themeColors.secondary_color};
`

export const DecreaseButton = styled(IncreaseButton)``
