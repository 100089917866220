import styled from 'styled-components'
import { themeColors } from '../../styles/theme.colors'

export const BrandsTemplateWrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const LinkWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 1.5rem;
  color: ${themeColors.grey_3};
`

export const CategoryBadgeWrapper = styled.section`
  margin-top: 8px;
  margin-bottom: 24px;
`

export const SearchWrapper = styled.section`
  width: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30px;
`

export const ScreenTitle = styled.span`
  font-size: 2.2rem;
  color: ${themeColors.black};
  display: inline-block;
  margin-bottom: 32px;
  text-align: center;
`

export const BrandsOptionsWrapper = styled.ul`
  display: grid;
  column-gap: 8px;
  row-gap: 12px;
  grid-template-columns: repeat(2, 1fr);
`

export const SelectBrandItem = styled.li`
  margin-bottom: 30px;
`
