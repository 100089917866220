import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Container } from '../../layout'
import { ICompletePurchase } from '../../interfaces'
import { routesNameEnum } from '../../routes/routesNameEnum'

// import { completePurchaseMock } from './mock'

import * as S from './styles'
import { getShopperBuys } from '../../services/shopperBuys.service'
import { tokenAccessContext } from '../../contexts/TokenAccess/tokenAccessContext'
import { useLoading } from '../../hooks'
import { LoadingComponent } from '../../components'

const CompletePurchasesTemplate: React.FC = () => {
  const navigate = useNavigate()
  const [completePurchasesData, setCompletePurchasesData] = useState<
    ICompletePurchase[]
  >([])
  const { token } = useContext(tokenAccessContext)
  const { isLoading, startLoading, stopLoading } = useLoading()
  const completePurchaseEmpty: ICompletePurchase[] = []

  const monthsNames = useMemo(() => {
    return [
      'JANEIRO',
      'FEVEREIRO',
      'MARÇO',
      'ABRIL',
      'MAIO',
      'JUNHO',
      'JULHO',
      'AGOSTO',
      'SETEMBRO',
      'OUTUBRO',
      'NOVEMBRO',
      'DEZEMBRO'
    ]
  }, [])

  const getAllCompletePurchases = () => {
    ;(async () => {
      try {
        startLoading()
        const response = await getShopperBuys(
          token.accessToken,
          localStorage.getItem('shopperId') ?? ''
        )
        setCompletePurchasesData(response.orders)
      } catch (error) {
        console.log(error)
        setCompletePurchasesData(completePurchaseEmpty)
      } finally {
        stopLoading()
      }
    })()
  }

  const goToPurchaseDetails = (purchase: ICompletePurchase) => {
    navigate(
      `${routesNameEnum.COMPLETE_PURCHASE_DETAIL}/${purchase.order_id}`,
      {
        state: {
          purchase
        }
      }
    )
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getAllCompletePurchases(), [])

  const sortedPurchases = useMemo(() => {
    return completePurchasesData.sort(
      (a, b) => new Date(b.data).getTime() - new Date(a.data).getTime()
    )
  }, [completePurchasesData])

  const renderPurchase = (purchase: ICompletePurchase) => {
    const dateOfThePurchase = new Date(purchase.data)
    const day = dateOfThePurchase.getDate()
    const month = dateOfThePurchase.getMonth()
    const monthFormatted = monthsNames[month].slice(0, 3)

    return (
      <S.PurchaseDataWrapper
        onClick={() => goToPurchaseDetails(purchase)}
        key={purchase.order_id}
      >
        <S.CardWithDateOfThePurchase>
          <S.DayThePurchaseText>{day}</S.DayThePurchaseText>
          <S.MonthThePurchaseText>{monthFormatted}</S.MonthThePurchaseText>
        </S.CardWithDateOfThePurchase>
        <section className="infos">
          <S.PurChaseCodeText>#{purchase.order_id}</S.PurChaseCodeText>
          <S.CustomerInfoText>Cliente: {purchase.name}</S.CustomerInfoText>
          <S.QuantityOfPieces>
            Peças: {purchase.total_products}
          </S.QuantityOfPieces>
        </section>
      </S.PurchaseDataWrapper>
    )
  }
  return (
    <S.CompletePurchasesWrapper>
      <Container>
        <S.PurchasesHeading>
          <S.ScreenTitle>Selecione uma compra</S.ScreenTitle>
        </S.PurchasesHeading>
        {isLoading ? (
          <LoadingComponent />
        ) : completePurchasesData.length === 0 ? (
          <S.NotFound>Nenhuma compra encontrada</S.NotFound>
        ) : (
          sortedPurchases.map(renderPurchase)
        )}
      </Container>
    </S.CompletePurchasesWrapper>
  )
}

export default CompletePurchasesTemplate
