export function savingDataInLocastorage<T>(
  data: T,
  key: string,
  emptyValue: unknown
) {
  const dataOfTheLocal = window.localStorage.getItem(key)
  if (!data && !dataOfTheLocal) {
    window.localStorage.setItem(key, JSON.stringify(emptyValue))
    return
  }
  if (data) {
    window.localStorage.setItem(key, JSON.stringify(data))
  }
}

export function fillingDataWithDatasAlreadySavedInLocalstorage<S>(
  setState: (value: React.SetStateAction<S>) => void,
  key: string
) {
  const dataLocal = window.localStorage.getItem(key)
  if (dataLocal) {
    setState(JSON.parse(dataLocal))
  }
}
