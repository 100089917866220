import styled, { css } from 'styled-components'
import { themeColors } from '../../styles/theme.colors'
import { ButtonStyleTypeEnum, IStyleProps } from './types'

export const buttonFormatStyle = {
  DEFAULT: () => css`
    padding: 16px;
    font-size: 2rem;
    cursor: pointer;
    min-width: 250px;
    border-radius: 45px;
    text-transform: uppercase;
    transition: all ease-in-out 300ms;
  `,

  RECTANGULAR: () => css`
    padding: 10px;
    border: none;
    font-size: 1.5rem;
    max-width: 355px;
    max-width: 100%;
    border-radius: 3px;
  `
}

export const ButtonWrapper = styled.button<IStyleProps>`
  ${(props) => css`
    ${buttonFormatStyle[props.formatType]}

    width: 100%;
    font-family: 'Brandon Regular';

    &:disabled {
      cursor: not-allowed;
      background-color: ${themeColors.grey};
    }

    &:hover {
      transform: translateY(-5px);
      opacity: 0.9;
    }

    ${props.styleType === ButtonStyleTypeEnum.PRIMARY
      ? css`
          border: none;
          color: ${themeColors.white};
          background-color: ${themeColors.secondary_color};
        `
      : css`
          color: ${themeColors.secondary_color};
          background-color: ${themeColors.white};
          border: 1px solid ${themeColors.secondary_color};
        `}
  `}
`
