import React, {
  ChangeEvent,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { useNavigate } from 'react-router-dom'
import {
  ButtonComponent,
  CustomerInfoLabel,
  SelectComponent
} from '../../components'
import { selectedCustomerContext } from '../../contexts/SelectedCustomer/selectedCustomer'
import { Container } from '../../layout'
import { ISelectOption } from '../../interfaces'
import { ISizeOptions } from '../../interfaces/genderSize'
import { routesNameEnum } from '../../routes/routesNameEnum'
import { selectedGenderSizeContext } from '../../contexts/SelectedGenderSize/selectedGenderSize'

import { sizeOptions } from '../../utils/sizeOptions'
import { genderOptions } from '../../utils/genderOptions'

import * as S from './styles'

const ProductGenderSizeTemplate: React.FC = () => {
  const navigate = useNavigate()
  const { customer } = useContext(selectedCustomerContext)
  const { saveGenderSize } = useContext(selectedGenderSizeContext)
  const [searchString, setSearchString] = useState('')
  const [sizesList, setSizesList] = useState<ISizeOptions[]>([])
  const [sizesSearchResult, setSizesSearchResult] =
    useState<ISizeOptions[]>(sizesList)

  const [selectedGender, setSelectedGender] = useState<ISelectOption>({
    label: '',
    value: ''
  })
  const [selectedSize, setSelectedSize] = useState<ISelectOption>({
    label: '',
    value: ''
  })
  const genderSize = { gender: selectedGender, size: selectedSize }

  const handleSelectedGender = useCallback((value: ISelectOption) => {
    setSelectedGender(value)
  }, [])

  const handleSelectedSize = useCallback((value: ISelectOption) => {
    setSelectedSize(value)
  }, [])

  const handleChangeSearchValue = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      setSearchString(value)

      if (sizesList.length) {
        const categoriesFiltered = sizesList.filter((size) =>
          size.label.toLowerCase().includes(value.toLowerCase())
        )
        categoriesFiltered.length === 0
          ? setSizesSearchResult([
              { label: 'TAMANHO NÃO ENCONTRADO', value: '0', genderId: '0' }
            ])
          : setSizesSearchResult(categoriesFiltered)
      }
    },
    [sizesList]
  )

  useEffect(() => {
    const filteredSizeByGender = sizeOptions.filter(
      (size) => size.genderId === selectedGender.value
    )
    if (filteredSizeByGender.length === 0) {
      setSizesList([{ label: 'SELECIONE O GÊNERO', value: '0', genderId: '0' }])
      setSizesSearchResult([
        { label: 'SELECIONE O GÊNERO', value: '0', genderId: '0' }
      ])
    } else {
      setSizesList(filteredSizeByGender)
      setSizesSearchResult(filteredSizeByGender)
    }
  }, [selectedGender])

  useEffect(() => {
    if (selectedSize.label !== searchString) {
      setSelectedSize({
        label: '',
        value: ''
      })
    }
    // eslint-disable-next-line
  }, [searchString])

  const proceedAndSaveGenderSize = () => {
    saveGenderSize?.(genderSize)
    navigate(routesNameEnum.CATEGORIES, { state: { email: customer?.email } })
  }

  return (
    <Fragment>
      <CustomerInfoLabel
        label={`Cliente: ${customer?.email ? customer?.email : customer?.name}`}
      />
      <Container>
        <S.ProductGenderSizeWrapper>
          <S.SelectSection>
            <S.SelectWrapperTop>
              <S.Label>Gênero da peça</S.Label>
              <SelectComponent
                options={genderOptions}
                selectedOption={selectedGender}
                handleSelectedOption={handleSelectedGender}
              />
            </S.SelectWrapperTop>
            <S.SelectWrapperBottom>
              <S.Label>Tamanho da peça</S.Label>
              <SelectComponent
                options={sizesSearchResult}
                selectedOption={selectedSize}
                handleSelectedOption={handleSelectedSize}
                isSearchSelect
                handleChangeSearchValue={handleChangeSearchValue}
                searchString={searchString}
                setSearchString={setSearchString}
              />
            </S.SelectWrapperBottom>
          </S.SelectSection>
          <S.ButtonWrapper>
            <ButtonComponent
              disabled={!selectedGender.value || !selectedSize.value}
              onClick={proceedAndSaveGenderSize}
            >
              CONTINUAR
            </ButtonComponent>
          </S.ButtonWrapper>
        </S.ProductGenderSizeWrapper>
      </Container>
    </Fragment>
  )
}

export default ProductGenderSizeTemplate
