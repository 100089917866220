import styled from 'styled-components'

export const HomeTemplateWrapper = styled.section`
  width: 100%;
  min-height: 100vh;
  position: relative;

  display: grid;
  grid-template-rows: repeat(5, 1fr);
`
