import styled from 'styled-components'
import { themeColors } from '../../styles/theme.colors'

export const HeaderWrapper = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: ${themeColors.white};
`

export const NavWrapper = styled.nav`
  width: 100%;
`

export const ActionsWrapper = styled.ul`
  display: flex;
  gap: 31px;
  justify-content: flex-end;
  align-items: center;

  > li:nth-child(2) {
    display: flex;
    align-items: center;
  }
`

export const ActionItem = styled.li`
  display: flex;
  font-size: 2rem;
  font-family: 'Brandon Regular';
  text-transform: uppercase;
  cursor: pointer;
`
