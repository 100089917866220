import styled from 'styled-components'
import { themeColors } from '../../styles/theme.colors'

export const RegisterCustomerWrapper = styled.form`
  width: 100%;
`

export const InputContainer = styled.div`
  margin-bottom: 30px;
`

export const InputLabel = styled.label`
  font-size: 2rem;
  display: inline-block;
  margin-bottom: 4px;
  color: ${themeColors.black};
`

export const PersonalDataWrapper = styled.section``

export const BankDataWrapper = styled.section`
  width: 100%;
`

export const BankTitleWrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const BankDataTitle = styled.span`
  display: inline-block;
  font-size: 1.8rem;
  text-align: center;
  color: ${themeColors.black};
  margin-bottom: 24px;
`

export const TypePersonSelectLabel = styled.label``
