import React, { createContext, useEffect, useState } from 'react'

import { addCustomer, clearSelectedCustomer } from './actions'
import { ICustomer } from '../../interfaces'

import {
  ISelectedCustomerContextType,
  ISelectedCustomerContextProviderProps
} from './types'
import {
  savingDataInLocastorage,
  fillingDataWithDatasAlreadySavedInLocalstorage
} from '../../utils'

const INITIAL_STATE = {
  customer: null
}

export const selectedCustomerContext =
  createContext<ISelectedCustomerContextType>(INITIAL_STATE)

const SelectedCustomerContextProvider = ({
  children
}: ISelectedCustomerContextProviderProps) => {
  const [customer, setCustomer] = useState<Partial<ICustomer> | null>(null)

  const saveCustomerSelected = (customer: ICustomer) => {
    setCustomer(addCustomer(customer))
  }

  const resetSelectedCustomer = () => {
    window.localStorage.removeItem('customer')
    setCustomer(clearSelectedCustomer())
  }

  const savingSelectedCustomerInLocastorage = () => {
    savingDataInLocastorage(customer, 'customer', null)
  }

  const fillingCustomerDataWithDatasAlreadySavedInLocalstorage = () => {
    fillingDataWithDatasAlreadySavedInLocalstorage(setCustomer, 'customer')
  }

  useEffect(fillingCustomerDataWithDatasAlreadySavedInLocalstorage, [])
  useEffect(savingSelectedCustomerInLocastorage, [customer])

  const value: ISelectedCustomerContextType = {
    customer,
    saveCustomerSelected,
    resetSelectedCustomer
  }

  return (
    <selectedCustomerContext.Provider value={value}>
      {children}
    </selectedCustomerContext.Provider>
  )
}

export default SelectedCustomerContextProvider
