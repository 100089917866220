import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  useCallback
} from 'react'
import { useNavigate } from 'react-router-dom'

import { routesNameEnum } from '../../routes/routesNameEnum'

import { Container } from '../../layout'
import {
  InputComponent,
  ButtonComponent,
  SelectRadioComponent,
  LoadingComponent
} from '../../components'
import { useLoading } from '../../hooks/useLoading'

import { ICustomer, IStandByPurchase } from '../../interfaces'
import { useCartContext } from '../../contexts/CartProvider/useCartContext'
import { selectedCustomerContext } from '../../contexts/SelectedCustomer/selectedCustomer'
import { selectedGenderSizeContext } from '../../contexts/SelectedGenderSize/selectedGenderSize'

import { searchCustomer } from '../../services'
import { tokenAccessContext } from '../../contexts/TokenAccess/tokenAccessContext'
import {
  standByPurchaseGet,
  standByPurchaseRemove
} from '../../services/standby.service'
import { savingDataInLocastorage, showSuccessToast } from '../../utils'

import * as S from './styles'

const NewBuyTemplate: React.FC = () => {
  const navigate = useNavigate()
  const { token } = useContext(tokenAccessContext)
  const { saveCustomerSelected } = useContext(selectedCustomerContext)
  const { saveGenderSize } = useContext(selectedGenderSizeContext)
  const { actions: CartContextActions } = useCartContext()

  const [wantedCustomer, setWantedCustomer] = useState('')
  const [selectedValue, setSelectedValue] = useState('')
  const [customer, setCustomer] = useState<ICustomer>()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [standbyPurchasesList, setStandbyPurchasesList] = useState<
    IStandByPurchase[]
  >([])
  const [selectedStandbyPurchase, setSelectedStandbyPurchase] = useState('')
  const [customersListFiltered, setCustomersListFiltered] = useState<
    ICustomer[]
  >([])
  const [searchTimeout, setSearchTimeout] = useState<
    NodeJS.Timeout | undefined
  >(undefined)

  const { isLoading, startLoading, stopLoading } = useLoading()

  const gotToRegisterCustomer = () => navigate(routesNameEnum.REGISTER_CUSTOMER)
  const shopperId = Number(localStorage.getItem('shopperId'))

  const handleChangeSelectedCustomer = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      setSelectedValue(value)
    },
    []
  )

  const handleChangeSelectedStandbyPurchase = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      setSelectedStandbyPurchase(value)
    },
    []
  )

  const handleChangeCustomerSearchValue = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.trim()
      setWantedCustomer(value)
      if (value.length === 0) {
        setCustomersListFiltered([])
      }
      if (value.length >= 3) {
        if (searchTimeout) {
          clearTimeout(searchTimeout)
        }
        startLoading()
        setSearchTimeout(
          setTimeout(async () => {
            const response = await searchCustomer(
              token.accessToken,
              value.toLocaleLowerCase(),
              0
            )
            if (response && response.data) {
              const updatedData = {
                ...response.data,
                document: value
              }
              setCustomersListFiltered([updatedData] as unknown as ICustomer[])
            } else {
              setCustomersListFiltered([])
            }

            stopLoading()
          }, 1000)
        )
      }
    },
    [token, searchTimeout, startLoading, stopLoading]
  )

  useEffect(() => {
    async function getStandbyPurchases() {
      try {
        const response = await standByPurchaseGet(token.accessToken, shopperId)
        setStandbyPurchasesList(response.data)
      } catch (error) {
        console.error(`Error getting standby purchases: ${error}`)
      }
    }
    getStandbyPurchases()
  }, [token.accessToken, shopperId])

  const proceedWithTheSelectedClient = () => {
    if (customer) saveCustomerSelected?.(customer)
    CartContextActions?.clearCartAction?.()
    localStorage.removeItem('standById')
    navigate(routesNameEnum.PRODUCT_GENDER_SIZE)
  }

  const continueWithStandbyPurchases = () => {
    try {
      const standbyPurchase = standbyPurchasesList.find(
        (item) => item.id === parseInt(selectedStandbyPurchase)
      )
      if (standbyPurchase) {
        saveCustomerSelected?.(standbyPurchase.customer)
        CartContextActions?.clearCartAction?.()
        standbyPurchase.items.forEach((item) => {
          CartContextActions?.addItemToCartAction?.(item)
        })
        saveGenderSize?.(standbyPurchase.genderSize)
        savingDataInLocastorage(standbyPurchase?.id, 'standById', '')
        navigate(routesNameEnum.CART)
      }
    } catch (error) {
      console.error(`Error getting selected standby purchase: ${error}`)
    }
  }

  const removeStandbyPurchaseItem = async () => {
    try {
      const response = await standByPurchaseRemove(
        token.accessToken,
        selectedStandbyPurchase,
        shopperId
      )
      console.log(response)
      const updatedStandbyPurchasesList = standbyPurchasesList.filter(
        (item) => item.id !== parseInt(selectedStandbyPurchase)
      )
      setStandbyPurchasesList(updatedStandbyPurchasesList)
      setOpenModal(false)
      showSuccessToast('Compra removida com sucesso!')
    } catch (error) {
      console.error(`Error removing standby purchase: ${error}`)
    }
  }

  const formatPurchaseDate = (dateString: string) => {
    const date = new Date(dateString)
    const day = date.getDate()
    const month = date.getMonth() + 1
    return `${day}/${month}`
  }

  const renderCustomerList = () => {
    return (
      <Fragment>
        <S.CustomerListWrapper>
          {isLoading ? (
            <LoadingComponent />
          ) : customersListFiltered.length ? (
            customersListFiltered.map((customer) => (
              <S.CustomerItem key={customer.id}>
                <SelectRadioComponent
                  id="customer"
                  label={customer.name}
                  option={customer.document || '0'}
                  description={customer.email}
                  selectedValue={selectedValue}
                  onClick={() => setCustomer(customer)}
                  handleChange={handleChangeSelectedCustomer}
                />
              </S.CustomerItem>
            ))
          ) : (
            <S.CustomerNotFoundText>
              Cliente não encontrado
            </S.CustomerNotFoundText>
          )}
        </S.CustomerListWrapper>
        <S.ConfirmButtonWrapper>
          <ButtonComponent
            disabled={!selectedValue}
            onClick={proceedWithTheSelectedClient}
          >
            Confirmar
          </ButtonComponent>
        </S.ConfirmButtonWrapper>
      </Fragment>
    )
  }

  const renderShoppingListOnStandby = () => {
    return (
      <Fragment>
        <S.OrText>Ou</S.OrText>
        <S.StandbyPurchasesTitle>Compras em Standby</S.StandbyPurchasesTitle>
        {standbyPurchasesList.length > 0 ? (
          <S.StandbyPurchasesWrapper>
            {standbyPurchasesList.map((purchase) => (
              <S.StandbyPurchaseItem key={purchase.id}>
                <SelectRadioComponent
                  id={`standbyPurchase_${purchase.id}`}
                  label={`
                  ${formatPurchaseDate(purchase.when)} - 
                  ${purchase.customer.name}
                `}
                  option={`${purchase.id}`}
                  description={purchase.customer.email}
                  selectedValue={selectedStandbyPurchase}
                  handleChange={handleChangeSelectedStandbyPurchase}
                />
                {selectedStandbyPurchase === `${purchase.id}` && (
                  <S.RemoveStandbyPurchaseItem
                    onClick={() => setOpenModal(true)}
                  >
                    REMOVER
                  </S.RemoveStandbyPurchaseItem>
                )}
              </S.StandbyPurchaseItem>
            ))}
            <S.ContinueWithStandbyPurchasesButton>
              <ButtonComponent
                disabled={!selectedStandbyPurchase}
                onClick={continueWithStandbyPurchases}
              >
                Continuar
              </ButtonComponent>
            </S.ContinueWithStandbyPurchasesButton>
          </S.StandbyPurchasesWrapper>
        ) : (
          <S.StandbyMessage>Nenhuma compra em standby salva</S.StandbyMessage>
        )}
      </Fragment>
    )
  }

  return (
    <Container>
      <S.NewBuyTemplateWrapper>
        <S.NewBuyPageTitle>Selecione o cliente</S.NewBuyPageTitle>
        <InputComponent
          value={wantedCustomer}
          placeholder="Buscar por documento"
          onChange={handleChangeCustomerSearchValue}
        />

        {wantedCustomer ? (
          renderCustomerList()
        ) : (
          <Fragment>
            <S.OrText>Ou</S.OrText>
            <ButtonComponent onClick={gotToRegisterCustomer}>
              Adicione
            </ButtonComponent>
            {renderShoppingListOnStandby()}
          </Fragment>
        )}
      </S.NewBuyTemplateWrapper>

      <S.CustomModal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
      >
        <S.ModalTitle>Deseja remover esta compra?</S.ModalTitle>
        <S.ModalWrapper>
          <S.CancelButton onClick={() => setOpenModal(false)}>
            Cancelar
          </S.CancelButton>
          <S.ConfirmButton onClick={removeStandbyPurchaseItem}>
            Remover
          </S.ConfirmButton>
        </S.ModalWrapper>
      </S.CustomModal>
    </Container>
  )
}

export default NewBuyTemplate
