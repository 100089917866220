import React, { Fragment, useContext, useEffect, useMemo } from 'react'

import HomeNavigationButton from './HomeNavigationButton'

import { INavigationButton } from './type'
import { routesNameEnum } from '../../routes/routesNameEnum'
import { ButtonBgColorEnum } from './HomeNavigationButton/types'
import TutorialOfTheHomeComponent from './TutorialOfTheHomeComponent'
import { homeTutorialContext } from '../../contexts/HomeTutorial/homeTutorial'

import * as S from './styles'

const HomeTemplate: React.FC = () => {
  const { showHomeTutorialState, showHomeTutorialFn } =
    useContext(homeTutorialContext)

  useEffect(() => {
    setTimeout(() => showHomeTutorialFn?.(false), 4000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const screensToBrowse: INavigationButton[] = useMemo(() => {
    return [
      {
        bgColor: ButtonBgColorEnum.PRIMARY,
        label: 'Nova Compra',
        link: routesNameEnum.NEW_BUY,
        available: true
      },
      {
        bgColor: ButtonBgColorEnum.SECONDARY,
        label: 'Compras Finalizadas',
        link: routesNameEnum.COMPLETE_PURCHASES,
        available: true
      },
      {
        bgColor: ButtonBgColorEnum.BEIGE,
        label: 'Saldo',
        link: routesNameEnum.BALANCE,
        available: false
      },
      {
        bgColor: ButtonBgColorEnum.BLUE,
        label: 'Retiradas Pendentes',
        link: routesNameEnum.PENDING_WITHDRAWALS,
        available: false
      },
      {
        bgColor: ButtonBgColorEnum.GREEN,
        label: 'Agendar Entrega',
        link: routesNameEnum.SCHEDULE_DELIVERY,
        available: false
      }
    ]
  }, [])

  return (
    <Fragment>
      <S.HomeTemplateWrapper>
        {screensToBrowse.map((button) => (
          <HomeNavigationButton
            bgColor={button.bgColor}
            available={button.available}
            link={button.link}
            key={button.label}
          >
            {button.label}
          </HomeNavigationButton>
        ))}
        {showHomeTutorialState && <TutorialOfTheHomeComponent />}
      </S.HomeTemplateWrapper>
    </Fragment>
  )
}

export default HomeTemplate
