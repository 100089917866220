export enum routesNameEnum {
  HOME = '/',
  LOGIN = '/login',
  NEW_BUY = '/new-buy',
  PRODUCT_GENDER_SIZE = '/gender-size',
  CATEGORIES = '/categories',
  REGISTER_CUSTOMER = '/register-customer',
  SELECT_BRAND = '/categories',
  CHOICE_OF_PRODUCT_QUALITY = '/choice-of-product-quality',
  CART = '/cart',
  ORDER_QUESTIONNAIRE = '/order-questionnaire',
  SHARE_PURCHASE = '/share-purchase',
  COMPLETE_PURCHASES = '/complete-purchases',
  COMPLETE_PURCHASE_DETAIL = '/complete-purchase-detail',
  BALANCE = '/balance',
  PENDING_WITHDRAWALS = '/pending-withdrawals',
  SCHEDULE_DELIVERY = '/schedule-delivery'
}
