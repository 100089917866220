import { axiosInstance } from './api'

import { showErrorToast } from '../utils'
import { ICategorie, IPaginateData } from '../interfaces'

export async function fetchCategories(
  authToken: string
): Promise<IPaginateData<ICategorie> | undefined> {
  try {
    const response = await axiosInstance.get(
      '/categories?page=1&perpage=1000',
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    )
    return response.data
  } catch (err) {
    showErrorToast('Algo deu errado, tente novamente!')
  }
}
