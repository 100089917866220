import styled from 'styled-components'
import { themeColors } from '../../styles/theme.colors'

export const ProductQualityWrapper = styled.section`
  width: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

export const LinkWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 1.5rem;
  color: ${themeColors.grey_3};
`

export const BrandAndCategoryWrapper = styled.section`
  width: 100%;
`

export const ButtonWrapper = styled.section`
  width: 100%;
  max-width: 100%;
  margin: 8px auto;
`

export const ProductsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const ProductsWrapper = styled.ul`
  width: 100%;
  max-height: 50vh;
  overflow: hidden;
  overflow-y: scroll;
`

export const ProductItem = styled.li`
  margin-bottom: 16px;
`

export const ScreenTitle = styled.span`
  font-size: 2.2rem;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 24px;
  display: inline-block;
  color: ${themeColors.black};
`

export const ButtonConfirmWrapper = styled.section`
  width: 100%;
  margin-top: 16px;
`

export const HangTagsWrapper = styled.section``

export const TagImage = styled.img`
  max-width: 100%;
`
