import React from 'react'

import { PageTemplate, OrderQuestionnaireTemplate } from '../../templates'

const OrderQuestionnaire: React.FC = () => {
  return (
    <PageTemplate>
      <OrderQuestionnaireTemplate />
    </PageTemplate>
  )
}

export default OrderQuestionnaire
