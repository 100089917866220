import React from 'react'

import styled from 'styled-components'

const ContainerWrapper = styled.section`
  width: 100%;
  padding: 16px;
`

const Container: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <ContainerWrapper>{children}</ContainerWrapper>
}

export default Container
