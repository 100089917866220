import styled from 'styled-components'
import { themeColors } from '../../styles/theme.colors'

export const SwitchWrapper = styled.section`
  .switch-component {
    .react-switch-bg {
      padding: 3px;
      border: 1px solid ${themeColors.primary_color};
    }

    .react-switch-handle {
      width: 15px;
      height: 15px;
      background: ${themeColors.primary_color} !important;
    }
  }
`
