import styled from 'styled-components'
import { themeColors } from '../../styles/theme.colors'
import { ButtonWrapper as ButtonComponent } from '../../components/ButtonComponent/styles'

export const OrderQuestionnaireTemplateWrapper = styled.section``

export const PaymentMethodSelectWrapper = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 35px;
`

export const SelectContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  margin-top: 12px;
`

export const SelectWrapperTop = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 20px;
  margin-bottom: 12px;
  position: relative;
  z-index: 2;
`

export const SelectWrapperBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  gap: 12px;
  z-index: 1;
`

export const ScreenTitle = styled.span`
  font-size: 2.2rem;
  text-align: center;
  display: inline-block;
  color: ${themeColors.black};
`

export const WithdrawalQuestionnaireWrapper = styled.section`
  > ${ScreenTitle} {
    margin: 0 auto;
  }
`

export const QuantitySelectedWrapper = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const ReasonOptionsWrapper = styled.ul``

export const ReasonOptionItem = styled.li`
  margin-bottom: 15px;
`

export const ReasonsWrapper = styled.section``

export const GenericTitle = styled(ScreenTitle)`
  font-size: 1.8rem;
`

export const ButtonsActionsWrapper = styled.section`
  > ${ButtonComponent} {
    margin-top: 15px;
  }
`

export const PriceContainer = styled(ScreenTitle)`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 1.8rem;
`

export const SpinnerContainer = styled.div`
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 3px solid #fb523aad;
  border-radius: 50%;
  border-top-color: ${themeColors.primary_color};
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
`

export const ModalDetails = styled.h2`
  color: ${themeColors.primary_color};
  margin-top: 1rem;
  cursor: pointer;
`

export const ItemsContainer = styled.div`
  div {
    display: flex;
    align-items: center;
    margin-top: 10px;
    span {
      height: 4px;
      width: 4px;
      background-color: ${themeColors.secondary_color};
      border-radius: 50%;
      margin-right: 5px;
    }
  }
  p {
    font-size: 12px;
    max-width: 220px;
  }
`
