import React, {
  Fragment,
  useState,
  FormEvent,
  useEffect,
  useContext,
  useCallback,
  ChangeEvent
} from 'react'
import { useNavigate } from 'react-router-dom'

import {
  LogoComponent,
  InputComponent,
  ButtonComponent,
  ConfirmationCodeInput
} from '../../components'

import { showErrorToast } from '../../utils'
import { useForm } from '../../hooks/useForm'
import { userContext } from '../../contexts/User/userContext'
import { routesNameEnum } from '../../routes/routesNameEnum'
import { checkingTypedEmail, loginService, termsService } from '../../services'
import { tokenAccessContext } from '../../contexts/TokenAccess/tokenAccessContext'

import * as S from './styles'

const LoginTemplate: React.FC = () => {
  const [showConfirmationCodeInputs, setShowConfirmationCodeInputs] =
    useState(false)
  const [confirmationCodeCharacters, setConfirmationCodeCharacters] = useState({
    char1: '',
    char2: '',
    char3: '',
    char4: ''
  })
  const [confirmationCodeValue, setConfirmationCodeValue] = useState('')
  const [showInvalidCodeMessage, setShowInvalidCodeMessage] = useState(false)
  const [showTerms, setShowTerms] = useState(false)
  const [currentTermIndex, setCurrentTermIndex] = useState(0)
  const [userCredentials, setUserCreadentials] = useState({
    email: '',
    token: ''
  })
  const [termsData, setTermsData] = useState<
    Array<{ title: string; text: string }>
  >([])
  const { addUser } = useContext(userContext)
  const { addToken } = useContext(tokenAccessContext)

  const navigate = useNavigate()
  const emailInput = useForm('email')
  const userEmail = localStorage.getItem('userEmail')
  const accessToken = localStorage.getItem('accessToken')

  useEffect(() => {
    if (userEmail && accessToken) {
      addUser?.({ email: userEmail })
      addToken?.({ accessToken: accessToken, tokenType: 'Bearer' })
      navigate(routesNameEnum.HOME)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const { char1, char2, char3, char4 } = confirmationCodeCharacters
    setConfirmationCodeValue(
      String(char1 + char2 + char3 + char4).toUpperCase()
    )
  }, [confirmationCodeCharacters])

  // const buttonMustBeDisabled =
  //   !!emailInput.errorMessage || showInvalidCodeMessage

  const handleChangeConfirmationCodeValue = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      if (value.length === 0) setShowInvalidCodeMessage(true)
      else setShowInvalidCodeMessage(false)

      setConfirmationCodeCharacters({
        ...confirmationCodeCharacters,
        [event.target.id]: value
      })
    },
    [confirmationCodeCharacters]
  )

  const addTokenToLocalstorage = (token: string) => {
    localStorage.setItem('userEmail', emailInput.value)
    localStorage.setItem('accessToken', token)

    addUser?.({ email: emailInput.value })
    addToken?.({ accessToken: token, tokenType: 'Bearer' })
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (emailInput.validate() && !showConfirmationCodeInputs) {
      const response = await loginService(emailInput.value)
      if (!response.success) {
        showErrorToast(`Algo deu errado, tente novamente mais tarde`)
        return
      }
      setShowConfirmationCodeInputs(true)
    }

    if (emailInput.validate() && confirmationCodeValue.length === 4) {
      const response = await checkingTypedEmail({
        email: emailInput.value,
        code: confirmationCodeValue
      })

      if (!response.success) {
        showErrorToast(`Algo deu errado, tente novamente mais tarde`)
        return
      }

      if (response.terms === 1) {
        addTokenToLocalstorage(response.token)
        navigate(routesNameEnum.HOME)
      }

      localStorage.setItem('shopperId', response.shopperId)
      setUserCreadentials({ email: emailInput.value, token: response.token })
      setTermsData(response.termsData)
      setShowTerms(true)
    }
  }

  const handleAcceptTerms = async () => {
    await termsService(userCredentials)

    setCurrentTermIndex((prevState) => prevState + 1)
    if (currentTermIndex === termsData.length - 1) {
      addTokenToLocalstorage(userCredentials.token)
      navigate(routesNameEnum.HOME)
    }
  }

  return (
    <Fragment>
      {!showTerms ? (
        <S.LoginFormWrapper onSubmit={handleSubmit}>
          <LogoComponent />

          <InputComponent
            name="email"
            placeholder="Email"
            className="input-email"
            {...emailInput}
          />

          {showConfirmationCodeInputs ? (
            <ConfirmationCodeInput
              setShowInvalidCodeMessage={setShowInvalidCodeMessage}
              showInvalidCodeMessage={showInvalidCodeMessage}
              code={confirmationCodeCharacters}
              handleChangeConfirmationCodeValue={
                handleChangeConfirmationCodeValue
              }
              showConfirmationCodeInputs={showConfirmationCodeInputs}
            />
          ) : null}

          <ButtonComponent type="submit">
            {showConfirmationCodeInputs ? 'Continuar' : 'Entrar'}
          </ButtonComponent>
        </S.LoginFormWrapper>
      ) : termsData.length && currentTermIndex <= termsData.length ? (
        <S.TermsWrapper>
          <LogoComponent />

          <S.TermsOfUseTitle>Termos de uso</S.TermsOfUseTitle>
          <S.TermWrapper>
            <S.TermDescription
              dangerouslySetInnerHTML={{
                __html: termsData[currentTermIndex].text
              }}
            />
          </S.TermWrapper>

          <S.LoginButton>
            <ButtonComponent onClick={handleAcceptTerms}>
              Aprovar
            </ButtonComponent>
          </S.LoginButton>
        </S.TermsWrapper>
      ) : null}
    </Fragment>
  )
}

export default LoginTemplate
