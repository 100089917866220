import React from 'react'
import { BoxMessageComponent } from '../../../components'
import { BoxSizesEnum } from '../../../components/BoxMessageComponent/types'

import * as S from './styles'

const TutorialOfTheHomeComponent: React.FC = () => {
  return (
    <S.TutorialOfTheHomeComponentWrapper>
      <S.TutorialHeaderWrapper>
        <BoxMessageComponent
          data-aos="fade-up-right"
          style={{ position: 'absolute', left: '10px', top: '-8px' }}
          size={BoxSizesEnum.LITTLE}
          message="Clique no logo da Daz para voltar a tela inicial"
        />
        <BoxMessageComponent
          data-aos="fade-up-left"
          style={{ position: 'absolute', right: '10px', top: '-8px' }}
          size={BoxSizesEnum.HIGH}
          message="Se precisar de ajuda clique no simbolo de interrogação"
        />
      </S.TutorialHeaderWrapper>
      <S.TutorialCenterWrapper>
        <BoxMessageComponent
          data-aos="zoom-in-right"
          style={{
            position: 'absolute',
            right: '0px',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)'
          }}
          size={BoxSizesEnum.WIDE}
          message="Clique em uma opção para navegar"
        />
      </S.TutorialCenterWrapper>
    </S.TutorialOfTheHomeComponentWrapper>
  )
}

export default TutorialOfTheHomeComponent
