import styled, { css, keyframes } from 'styled-components'
import { themeColors } from '../../styles/theme.colors'
import { IInputComponentStyleProps } from './types'

export const animationEntry = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -10px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`

export const InputWrapper = styled.div`
  width: 100%;
`

export const InputElement = styled.input<IInputComponentStyleProps>`
  width: 100%;
  min-width: 300px;
  padding: 16px;
  outline: none;
  font-size: 20px;
  border-radius: 45px;
  ${({ inputColor }) =>
    css`
      color: ${inputColor};
    `};
  border: 1px solid ${themeColors.secondary_color};
`

export const ErrorMessage = styled.p`
  font-size: 14px;
  margin: 5px 0px 0px 14px;
  color: ${themeColors.error_color};
  animation: ${animationEntry} forwards ease-in-out 0.5s;
`
