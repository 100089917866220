import React, { useEffect, useState } from 'react'

import { DAZ_DEFAULT_ICON, formatPrice } from '../../utils'
import { useCartContext } from '../../contexts/CartProvider/useCartContext'
import ToggleSwitchComponent from '../ToggleSwitchComponent'

import { ICartItemComponentProps } from './types'

import * as S from './styles'

const CartItemComponent: React.FC<ICartItemComponentProps> = ({ item }) => {
  const { actions: CartContextActions, cartItems } = useCartContext()
  const { description, productImage, itemEnabledForPurchase, dazPrice } = item
  const [checkedState, setCheckedState] = useState(itemEnabledForPurchase)

  const image = productImage.includes('/static/media/')
    ? DAZ_DEFAULT_ICON
    : productImage

  const updatedItemInTheCart = () =>
    CartContextActions?.updateCartItemAction?.(cartItems, item, checkedState)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updatedItemInTheCart, [checkedState])

  return (
    <S.CartItemComponentWrapper>
      {!checkedState && <S.OverLay />}
      <S.CartItemImage src={image} alt={description} />
      <S.CartItemContentLeftWrapper>
        <S.CartItemDescriptionWrapper>
          <S.CartItemInfo>{description}</S.CartItemInfo>
          <S.CartItemInfo>{formatPrice(Number(dazPrice))}</S.CartItemInfo>
        </S.CartItemDescriptionWrapper>
        <ToggleSwitchComponent
          checkedState={checkedState}
          setCheckedState={setCheckedState}
        />
      </S.CartItemContentLeftWrapper>
    </S.CartItemComponentWrapper>
  )
}

export default CartItemComponent
