import React, { createContext, ReactNode, useEffect, useState } from 'react'

import { IUser } from '../../interfaces'
import { addUserAction, clearUserDataAction } from './actions'

import { IUserContextType } from './types'

const INITIAL_STATE = {
  user: null,
  setUser: null
}

export const userContext = createContext<IUserContextType>(INITIAL_STATE)

export interface IUserContextProviderProps {
  children: ReactNode
}

const UserContextProvider = ({ children }: IUserContextProviderProps) => {
  const [user, setUser] = useState<IUser | null>(null)

  const addUser = (userData: IUser) => setUser(addUserAction(userData))

  const resetUser = () => {
    window.localStorage.removeItem('user')
    setUser(clearUserDataAction())
  }

  const savingUserInLocalstorage = () => {
    const dataOfTheLocal = window.localStorage.getItem('user')
    if (!user && !dataOfTheLocal) {
      window.localStorage.setItem('user', JSON.stringify({}))
      return
    }
    if (user) {
      window.localStorage.setItem('user', JSON.stringify(user))
    }
  }

  const fillingUserDataWithDatasAlreadySavedInLocalstorage = () => {
    const dataLocal = window.localStorage.getItem('user')
    if (dataLocal) {
      setUser(JSON.parse(dataLocal))
    }
  }

  useEffect(fillingUserDataWithDatasAlreadySavedInLocalstorage, [])
  useEffect(savingUserInLocalstorage, [user])

  const value: IUserContextType = {
    user,
    addUser,
    resetUser
  }

  return <userContext.Provider value={value}>{children}</userContext.Provider>
}

export default UserContextProvider
