import React from 'react'

import * as S from './styles'

interface ILoadingComponent {
  width?: string
  height?: string
}

const LoadingComponent: React.FC<ILoadingComponent> = ({ width, height }) => {
  return (
    <S.SpinnerOverlay>
      <S.SpinnerContainer style={{ width: width, height: height }} />
    </S.SpinnerOverlay>
  )
}

export default LoadingComponent
