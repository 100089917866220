import React from 'react'
import ReactDOM from 'react-dom/client'
import Modal from 'react-modal'

import App from './App'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'

import { ToastContainer } from 'react-toastify'

import { GlobalStyle } from './styles/globalStyles'
import './styles/fonts.css'
import 'react-toastify/dist/ReactToastify.min.css'
import 'aos/dist/aos.css'

import {
  UserContextProvider,
  TokenContextProvider,
  CartContextProvider,
  HelpModalContextProvider,
  SelectedCustomerContextProvider,
  HomeTutorialContextProvider,
  SelectedGenderSizeContextProvider
} from './contexts'
import { clarity } from 'react-microsoft-clarity'
if (process.env.REACT_APP_CLARITY_ID)
  clarity.init(process.env.REACT_APP_CLARITY_ID)
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <UserContextProvider>
      <TokenContextProvider>
        <HomeTutorialContextProvider>
          <HelpModalContextProvider>
            <SelectedGenderSizeContextProvider>
              <SelectedCustomerContextProvider>
                <CartContextProvider>
                  <GlobalStyle />
                  <App />
                  <ToastContainer />
                </CartContextProvider>
              </SelectedCustomerContextProvider>
            </SelectedGenderSizeContextProvider>
          </HelpModalContextProvider>
        </HomeTutorialContextProvider>
      </TokenContextProvider>
    </UserContextProvider>
  </React.StrictMode>
)

serviceWorkerRegistration.register()

Modal.setAppElement('#root')
