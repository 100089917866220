import { ISizeOptions } from '../interfaces/genderSize'

export const sizeOptions: ISizeOptions[] = [
  {
    label: '0/3meses',
    value: '38',
    genderId: '5'
  },
  {
    label: '0/3MESES',
    value: '146',
    genderId: '4'
  },
  {
    label: '1 mes',
    value: '177',
    genderId: '4'
  },
  {
    label: '1 mes',
    value: '178',
    genderId: '5'
  },
  {
    label: '1/2 meses',
    value: '89',
    genderId: '5'
  },
  {
    label: '1/2 MESES',
    value: '102',
    genderId: '4'
  },
  {
    label: '10 anos - g',
    value: '135',
    genderId: '4'
  },
  {
    label: '10 anos - g',
    value: '49',
    genderId: '5'
  },
  {
    label: '10/12 anos',
    value: '20',
    genderId: '5'
  },
  {
    label: '10/12 ANOS',
    value: '163',
    genderId: '4'
  },
  {
    label: '10/12 meses',
    value: '40',
    genderId: '5'
  },
  {
    label: '10/12 MESES',
    value: '144',
    genderId: '4'
  },
  {
    label: '100 ML ',
    value: '119',
    genderId: '1'
  },
  {
    label: '100 ml ',
    value: '68',
    genderId: '2'
  },
  {
    label: '100 ML ',
    value: '255',
    genderId: '8'
  },
  {
    label: '11 anos',
    value: '50',
    genderId: '5'
  },
  {
    label: '11 ANOS',
    value: '134',
    genderId: '4'
  },
  {
    label: '12 ANOS - gg',
    value: '133',
    genderId: '4'
  },
  {
    label: '12 anos - gg',
    value: '51',
    genderId: '5'
  },
  {
    label: '12 meses / 1 ano',
    value: '187',
    genderId: '4'
  },
  {
    label: '12 meses / 1 ano',
    value: '188',
    genderId: '5'
  },
  {
    label: '12/18 meses',
    value: '58',
    genderId: '5'
  },
  {
    label: '12/18 MESES',
    value: '127',
    genderId: '4'
  },
  {
    label: '120 ML',
    value: '109',
    genderId: '1'
  },
  {
    label: '120 ML',
    value: '81',
    genderId: '2'
  },
  {
    label: '120 ml',
    value: '263',
    genderId: '8'
  },
  {
    label: '125 ml',
    value: '264',
    genderId: '8'
  },
  {
    label: '125 ML',
    value: '82',
    genderId: '2'
  },
  {
    label: '125 ML',
    value: '108',
    genderId: '1'
  },
  {
    label: '13 ANOS',
    value: '132',
    genderId: '4'
  },
  {
    label: '13 anos',
    value: '52',
    genderId: '5'
  },
  {
    label: '14',
    value: '21',
    genderId: '5'
  },
  {
    label: '14',
    value: '162',
    genderId: '4'
  },
  {
    label: '14 anos - gg',
    value: '53',
    genderId: '5'
  },
  {
    label: '14 ANOS - gg',
    value: '131',
    genderId: '4'
  },
  {
    label: '15',
    value: '22',
    genderId: '5'
  },
  {
    label: '15',
    value: '161',
    genderId: '4'
  },
  {
    label: '15 anos',
    value: '54',
    genderId: '5'
  },
  {
    label: '15 ANOS',
    value: '130',
    genderId: '4'
  },
  {
    label: '15 ml ',
    value: '267',
    genderId: '8'
  },
  {
    label: '150 ML',
    value: '256',
    genderId: '8'
  },
  {
    label: '150 ML',
    value: '118',
    genderId: '1'
  },
  {
    label: '150 ml',
    value: '69',
    genderId: '2'
  },
  {
    label: '15ML',
    value: '105',
    genderId: '1'
  },
  {
    label: '15ml',
    value: '85',
    genderId: '2'
  },
  {
    label: '16',
    value: '23',
    genderId: '5'
  },
  {
    label: '16',
    value: '160',
    genderId: '4'
  },
  {
    label: '16 anos - ggg',
    value: '55',
    genderId: '5'
  },
  {
    label: '16 ANOS - ggg',
    value: '129',
    genderId: '4'
  },
  {
    label: '17',
    value: '24',
    genderId: '5'
  },
  {
    label: '17',
    value: '159',
    genderId: '4'
  },
  {
    label: '18',
    value: '158',
    genderId: '4'
  },
  {
    label: '18',
    value: '25',
    genderId: '5'
  },
  {
    label: '18 anos - ggg',
    value: '99',
    genderId: '5'
  },
  {
    label: '18 anos - ggg',
    value: '100',
    genderId: '4'
  },
  {
    label: '18 MESES',
    value: '128',
    genderId: '4'
  },
  {
    label: '18 meses',
    value: '57',
    genderId: '5'
  },
  {
    label: '18/24 meses',
    value: '59',
    genderId: '5'
  },
  {
    label: '18/24 MESES',
    value: '126',
    genderId: '4'
  },
  {
    label: '19',
    value: '26',
    genderId: '5'
  },
  {
    label: '19',
    value: '157',
    genderId: '4'
  },
  {
    label: '2 meses',
    value: '179',
    genderId: '4'
  },
  {
    label: '2 meses',
    value: '180',
    genderId: '5'
  },
  {
    label: '2/4 meses',
    value: '171',
    genderId: '4'
  },
  {
    label: '2/4 meses',
    value: '172',
    genderId: '5'
  },
  {
    label: '20',
    value: '101',
    genderId: '4'
  },
  {
    label: '20',
    value: '90',
    genderId: '5'
  },
  {
    label: '200 ML',
    value: '78',
    genderId: '2'
  },
  {
    label: '200 ML',
    value: '112',
    genderId: '1'
  },
  {
    label: '200 ml',
    value: '260',
    genderId: '8'
  },
  {
    label: '21',
    value: '156',
    genderId: '4'
  },
  {
    label: '21',
    value: '27',
    genderId: '5'
  },
  {
    label: '22',
    value: '28',
    genderId: '5'
  },
  {
    label: '22',
    value: '155',
    genderId: '4'
  },
  {
    label: '23',
    value: '60',
    genderId: '4'
  },
  {
    label: '23',
    value: '125',
    genderId: '5'
  },
  {
    label: '236 ML',
    value: '106',
    genderId: '1'
  },
  {
    label: '236 ML',
    value: '84',
    genderId: '2'
  },
  {
    label: '236 ml ',
    value: '266',
    genderId: '8'
  },
  {
    label: '24',
    value: '154',
    genderId: '4'
  },
  {
    label: '24',
    value: '29',
    genderId: '5'
  },
  {
    label: '24 meses / 2 anos',
    value: '41',
    genderId: '5'
  },
  {
    label: '24 meses / 2 anos',
    value: '143',
    genderId: '4'
  },
  {
    label: '25',
    value: '123',
    genderId: '4'
  },
  {
    label: '25',
    value: '63',
    genderId: '5'
  },
  {
    label: '25 ML',
    value: '76',
    genderId: '2'
  },
  {
    label: '25 ML',
    value: '114',
    genderId: '1'
  },
  {
    label: '25 ml',
    value: '258',
    genderId: '8'
  },
  {
    label: '250 ml',
    value: '189',
    genderId: '1'
  },
  {
    label: '250 ml',
    value: '190',
    genderId: '2'
  },
  {
    label: '26',
    value: '153',
    genderId: '4'
  },
  {
    label: '26',
    value: '30',
    genderId: '5'
  },
  {
    label: '27',
    value: '64',
    genderId: '5'
  },
  {
    label: '27',
    value: '122',
    genderId: '4'
  },
  {
    label: '28',
    value: '152',
    genderId: '4'
  },
  {
    label: '28',
    value: '32',
    genderId: '5'
  },
  {
    label: '29',
    value: '33',
    genderId: '5'
  },
  {
    label: '29',
    value: '151',
    genderId: '4'
  },
  {
    label: '3 ANOS',
    value: '142',
    genderId: '4'
  },
  {
    label: '3 anos',
    value: '42',
    genderId: '5'
  },
  {
    label: '3 MESES',
    value: '103',
    genderId: '4'
  },
  {
    label: '3 meses',
    value: '88',
    genderId: '5'
  },
  {
    label: '3/6 MESES',
    value: '145',
    genderId: '4'
  },
  {
    label: '3/6 meses',
    value: '39',
    genderId: '5'
  },
  {
    label: '30',
    value: '34',
    genderId: '5'
  },
  {
    label: '30',
    value: '150',
    genderId: '4'
  },
  {
    label: '30 ML',
    value: '113',
    genderId: '1'
  },
  {
    label: '30 ML',
    value: '77',
    genderId: '2'
  },
  {
    label: '30 ml',
    value: '259',
    genderId: '8'
  },
  {
    label: '300 ml',
    value: '265',
    genderId: '8'
  },
  {
    label: '300 ML',
    value: '107',
    genderId: '1'
  },
  {
    label: '300 ML',
    value: '83',
    genderId: '2'
  },
  {
    label: '31',
    value: '149',
    genderId: '4'
  },
  {
    label: '31',
    value: '35',
    genderId: '5'
  },
  {
    label: '32',
    value: '36',
    genderId: '5'
  },
  {
    label: '32',
    value: '148',
    genderId: '4'
  },
  {
    label: '33',
    value: '147',
    genderId: '4'
  },
  {
    label: '33',
    value: '37',
    genderId: '5'
  },
  {
    label: '33',
    value: '7',
    genderId: '2'
  },
  {
    label: '34',
    value: '8',
    genderId: '2'
  },
  {
    label: '34',
    value: '195',
    genderId: '4'
  },
  {
    label: '34',
    value: '197',
    genderId: '5'
  },
  {
    label: '35',
    value: '196',
    genderId: '5'
  },
  {
    label: '35',
    value: '194',
    genderId: '4'
  },
  {
    label: '35',
    value: '9',
    genderId: '2'
  },
  {
    label: '36',
    value: '10',
    genderId: '2'
  },
  {
    label: '36',
    value: '92',
    genderId: '1'
  },
  {
    label: '37',
    value: '93',
    genderId: '1'
  },
  {
    label: '37',
    value: '11',
    genderId: '2'
  },
  {
    label: '38',
    value: '12',
    genderId: '2'
  },
  {
    label: '38',
    value: '73',
    genderId: '1'
  },
  {
    label: '39',
    value: '74',
    genderId: '1'
  },
  {
    label: '39',
    value: '13',
    genderId: '2'
  },
  {
    label: '4',
    value: '243',
    genderId: '7'
  },
  {
    label: '4 anos - p',
    value: '43',
    genderId: '5'
  },
  {
    label: '4 anos - p',
    value: '141',
    genderId: '4'
  },
  {
    label: '4 meses',
    value: '181',
    genderId: '4'
  },
  {
    label: '4 meses',
    value: '182',
    genderId: '5'
  },
  {
    label: '40',
    value: '75',
    genderId: '2'
  },
  {
    label: '40',
    value: '14',
    genderId: '1'
  },
  {
    label: '41',
    value: '15',
    genderId: '1'
  },
  {
    label: '41',
    value: '91',
    genderId: '2'
  },
  {
    label: '42',
    value: '16',
    genderId: '1'
  },
  {
    label: '43',
    value: '17',
    genderId: '1'
  },
  {
    label: '43',
    value: '269',
    genderId: '2'
  },
  {
    label: '44',
    value: '18',
    genderId: '1'
  },
  {
    label: '45',
    value: '65',
    genderId: '1'
  },
  {
    label: '46',
    value: '94',
    genderId: '1'
  },
  {
    label: '47',
    value: '95',
    genderId: '1'
  },
  {
    label: '5 anos - p',
    value: '140',
    genderId: '4'
  },
  {
    label: '5 anos - p',
    value: '44',
    genderId: '5'
  },
  {
    label: '5 meses',
    value: '183',
    genderId: '4'
  },
  {
    label: '5 meses',
    value: '184',
    genderId: '5'
  },
  {
    label: '50 ML',
    value: '254',
    genderId: '8'
  },
  {
    label: '50 ml',
    value: '67',
    genderId: '2'
  },
  {
    label: '50 ML',
    value: '120',
    genderId: '1'
  },
  {
    label: '6 anos - m',
    value: '139',
    genderId: '4'
  },
  {
    label: '6 anos - m',
    value: '45',
    genderId: '5'
  },
  {
    label: '6 meses',
    value: '185',
    genderId: '4'
  },
  {
    label: '6 meses',
    value: '186',
    genderId: '5'
  },
  {
    label: '6/9 meses',
    value: '173',
    genderId: '4'
  },
  {
    label: '6/9 meses',
    value: '174',
    genderId: '5'
  },
  {
    label: '60 ml',
    value: '261',
    genderId: '8'
  },
  {
    label: '60 ML',
    value: '111',
    genderId: '1'
  },
  {
    label: '60 ML',
    value: '79',
    genderId: '2'
  },
  {
    label: '65 ml',
    value: '86',
    genderId: '2'
  },
  {
    label: '65 ML',
    value: '104',
    genderId: '1'
  },
  {
    label: '65 ml ',
    value: '268',
    genderId: '8'
  },
  {
    label: '7 anos - m',
    value: '138',
    genderId: '4'
  },
  {
    label: '7 anos - m',
    value: '46',
    genderId: '5'
  },
  {
    label: '75 ML',
    value: '71',
    genderId: '2'
  },
  {
    label: '75 ML',
    value: '116',
    genderId: '1'
  },
  {
    label: '75 ml',
    value: '257',
    genderId: '8'
  },
  {
    label: '8 anos - m',
    value: '137',
    genderId: '4'
  },
  {
    label: '8 anos - m',
    value: '47',
    genderId: '5'
  },
  {
    label: '80 ML',
    value: '110',
    genderId: '1'
  },
  {
    label: '80 ML',
    value: '80',
    genderId: '2'
  },
  {
    label: '80 ml',
    value: '262',
    genderId: '8'
  },
  {
    label: '9 ANOS',
    value: '136',
    genderId: '4'
  },
  {
    label: '9 anos',
    value: '48',
    genderId: '5'
  },
  {
    label: '9 meses',
    value: '62',
    genderId: '5'
  },
  {
    label: '9 MESES',
    value: '124',
    genderId: '4'
  },
  {
    label: '9/12 meses',
    value: '175',
    genderId: '4'
  },
  {
    label: '9/12 meses',
    value: '176',
    genderId: '5'
  },
  {
    label: 'g',
    value: '203',
    genderId: '5'
  },
  {
    label: 'g',
    value: '209',
    genderId: '4'
  },
  {
    label: 'G',
    value: '273',
    genderId: '2'
  },
  {
    label: 'g',
    value: '242',
    genderId: '7'
  },
  {
    label: 'G - 44/46',
    value: '252',
    genderId: '8'
  },
  {
    label: 'G - 44/46',
    value: '5',
    genderId: '2'
  },
  {
    label: 'G - 44/46/4',
    value: '166',
    genderId: '1'
  },
  {
    label: 'g1 plus size',
    value: '212',
    genderId: '2'
  },
  {
    label: 'g1 plus size',
    value: '216',
    genderId: '1'
  },
  {
    label: 'g2 plus size',
    value: '217',
    genderId: '1'
  },
  {
    label: 'g2 plus size',
    value: '213',
    genderId: '2'
  },
  {
    label: 'g3 plus size',
    value: '214',
    genderId: '2'
  },
  {
    label: 'g3 plus size',
    value: '218',
    genderId: '1'
  },
  {
    label: 'g4 plus size',
    value: '219',
    genderId: '1'
  },
  {
    label: 'g4 plus size',
    value: '215',
    genderId: '2'
  },
  {
    label: 'G5 PLUS SIZE',
    value: '220',
    genderId: '2'
  },
  {
    label: 'G5 PLUS SIZE',
    value: '223',
    genderId: '1'
  },
  {
    label: 'G6 PLUS SIZE',
    value: '224',
    genderId: '1'
  },
  {
    label: 'G6 PLUS SIZE',
    value: '221',
    genderId: '2'
  },
  {
    label: 'G7 PLUS SIZE',
    value: '222',
    genderId: '2'
  },
  {
    label: 'G7 PLUS SIZE',
    value: '225',
    genderId: '1'
  },
  {
    label: 'gg',
    value: '233',
    genderId: '4'
  },
  {
    label: 'gg',
    value: '231',
    genderId: '5'
  },
  {
    label: 'GG - 48/50',
    value: '249',
    genderId: '8'
  },
  {
    label: 'GG - 48/50',
    value: '2',
    genderId: '2'
  },
  {
    label: 'GG - 48/50/5',
    value: '165',
    genderId: '1'
  },
  {
    label: 'GGG - 52/54',
    value: '253',
    genderId: '8'
  },
  {
    label: 'GGG - 52/54',
    value: '6',
    genderId: '2'
  },
  {
    label: 'GGG - 52/54/6',
    value: '164',
    genderId: '1'
  },
  {
    label: 'm',
    value: '208',
    genderId: '4'
  },
  {
    label: 'm',
    value: '202',
    genderId: '5'
  },
  {
    label: 'm',
    value: '241',
    genderId: '7'
  },
  {
    label: 'M',
    value: '272',
    genderId: '2'
  },
  {
    label: 'M - 40/42',
    value: '248',
    genderId: '8'
  },
  {
    label: 'M - 40/42',
    value: '1',
    genderId: '2'
  },
  {
    label: 'M - 40/42/3',
    value: '167',
    genderId: '1'
  },
  {
    label: 'M PLUS SIZE',
    value: '227',
    genderId: '2'
  },
  {
    label: 'M PLUS SIZE',
    value: '229',
    genderId: '1'
  },
  {
    label: 'p',
    value: '240',
    genderId: '7'
  },
  {
    label: 'p',
    value: '201',
    genderId: '5'
  },
  {
    label: 'p',
    value: '207',
    genderId: '4'
  },
  {
    label: 'P',
    value: '271',
    genderId: '2'
  },
  {
    label: 'P - 36/38',
    value: '251',
    genderId: '8'
  },
  {
    label: 'P - 36/38',
    value: '4',
    genderId: '2'
  },
  {
    label: 'P - 36/38/2',
    value: '168',
    genderId: '1'
  },
  {
    label: 'P PLUS SIZE',
    value: '228',
    genderId: '1'
  },
  {
    label: 'P PLUS SIZE',
    value: '226',
    genderId: '2'
  },
  {
    label: 'pp',
    value: '230',
    genderId: '5'
  },
  {
    label: 'pp',
    value: '232',
    genderId: '4'
  },
  {
    label: 'PP - 32/34',
    value: '250',
    genderId: '8'
  },
  {
    label: 'PP - 32/34',
    value: '3',
    genderId: '2'
  },
  {
    label: 'PP - 34/1',
    value: '169',
    genderId: '1'
  },
  {
    label: 'rn',
    value: '170',
    genderId: '4'
  },
  {
    label: 'RN',
    value: '87',
    genderId: '5'
  },
  {
    label: 'sem numeração',
    value: '234',
    genderId: '2'
  },
  {
    label: 'sem numeração',
    value: '235',
    genderId: '1'
  },
  {
    label: 'sem numeração',
    value: '236',
    genderId: '4'
  },
  {
    label: 'sem numeração',
    value: '237',
    genderId: '5'
  },
  {
    label: 'SEM NUMERAÇÃO',
    value: '244',
    genderId: '7'
  },
  {
    label: 'SEM NUMERAÇÃO',
    value: '245',
    genderId: '8'
  },
  {
    label: 'unico',
    value: '239',
    genderId: '7'
  },
  {
    label: 'único',
    value: '198',
    genderId: '4'
  },
  {
    label: 'único',
    value: '199',
    genderId: '5'
  },
  {
    label: 'único',
    value: '246',
    genderId: '8'
  },
  {
    label: 'ÚNICO',
    value: '115',
    genderId: '1'
  },
  {
    label: 'único',
    value: '72',
    genderId: '2'
  }
]
