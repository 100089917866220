import React from 'react'

import { ButtonBgColorEnum, IHomeNavigationButton } from './types'

import * as S from './styles'
import { useNavigate } from 'react-router-dom'

const HomeNavigationButton: React.FC<IHomeNavigationButton> = ({
  bgColor = ButtonBgColorEnum.PRIMARY,
  children,
  link,
  available = true
}) => {
  const navigate = useNavigate()
  const handleClick = () => navigate(link)

  return (
    <S.HomeNavigationButtonWrapper
      disabled={!available}
      bgColor={bgColor}
      onClick={handleClick}
    >
      {children}
    </S.HomeNavigationButtonWrapper>
  )
}

export default HomeNavigationButton
