import { axiosInstance } from './api'

import { showErrorToast } from '../utils'

export async function getShopperBuys(authToken: string, document: string) {
  try {
    const response = await axiosInstance.get(
      `/shopperBuys/search?document=${document}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    )
    return response.data
  } catch (err) {
    console.error(err)
    showErrorToast('Algo deu errado, tente novamente!')
  }
}
