import styled from 'styled-components'
import { themeColors } from '../../styles/theme.colors'

export const CartItemComponentWrapper = styled.section`
  width: 100%;
  gap: 24px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
`

export const CartItemImage = styled.img`
  width: 100%;
  max-width: 100px;
`

export const CartItemContentLeftWrapper = styled.section`
  width: 100%;
  gap: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const CartItemDescriptionWrapper = styled.section``

export const CartItemInfo = styled.span`
  display: block;
  max-width: 110px;
  font-size: 1.8rem;
  margin-bottom: 4px;
  color: ${themeColors.black};
`

export const OverLay = styled.div`
  width: 100%;
  height: 100%;

  top: 0;
  z-index: 1000;
  opacity: 0.5;
  position: absolute;
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0.8);
`
