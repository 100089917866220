import styled from 'styled-components'
import { themeColors } from '../../styles/theme.colors'

export const CompletePurchaseDetailTemplateWrapper = styled.section``

export const PurchaseInfos = styled.section`
  display: flex;
  flex-direction: column;
`

export const GoBackButton = styled.button`
  width: 0;
  height: 0;
  border: none;
  transform: rotate(-90deg);
  background-color: transparent;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 32px solid ${themeColors.secondary_color};
`

export const GoBackButtonWrapperAndTitle = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`

export const ScreenTitle = styled.span`
  font-size: 2.2rem;
  margin-left: 102px;
  color: ${themeColors.black};
  display: inline-block;
  text-align: center;
`

export const InfoLabel = styled(ScreenTitle)`
  margin: 0;
  align-self: flex-start;
`

export const InfoPriceLabel = styled(InfoLabel)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1.8rem;
  margin-bottom: 8px;
`

export const ItemsWrapper = styled.ul``

export const Item = styled.li`
  margin-bottom: 32px;
`
