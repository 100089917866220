import styled, { css } from 'styled-components'
import { ButtonWrapper } from '../../components/ButtonComponent/styles'
import { themeColors } from '../../styles/theme.colors'

export const CartTemplateWrapper = styled.section`
  width: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

export const ScreenTitle = styled.span`
  font-size: 2.2rem;
  color: ${themeColors.black};
  display: inline-block;
  margin-bottom: 32px;
  text-align: center;
`

export const CartItemsWrapper = styled.ul``

export const CartItem = styled.li`
  margin-bottom: 20px;
`
export const ButtonAddItemWrapper = styled.section`
  margin-top: 24px;
`

export const InfosPricesWrapper = styled.section`
  width: 100%;
  margin-top: 90px;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

export const InfoTotalPrice = styled.span`
  font-size: 1.8rem;
  margin-bottom: 24px;
  display: inline-block;
  font-family: 'Brandon Bold';
  color: ${themeColors.black};
`

export const AdvanceCollectionButtonsWrapper = styled.section<{
  isFixedPosition: boolean
}>`
  width: 100%;
  margin-top: 40px;
  text-align: center;

  ${({ isFixedPosition }) =>
    isFixedPosition &&
    css`
      position: fixed;
      bottom: 0;
    `}

  > ${ButtonWrapper} {
    margin-bottom: 16px;
    max-width: 95%;
  }
`
