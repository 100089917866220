import { useContext, useEffect, useState } from 'react'

import { userContext } from '../contexts/User/userContext'

import { tokenAccessContext } from '../contexts/TokenAccess/tokenAccessContext'

export function useIsLogged() {
  const [isLogged, setIsLogged] = useState(false)

  const { user } = useContext(userContext)
  const { token } = useContext(tokenAccessContext)

  function handleVerifyUserIsLogged() {
    if (user?.email && token?.accessToken) {
      setIsLogged(true)
    } else {
      setIsLogged(false)
    }
  }

  useEffect(handleVerifyUserIsLogged, [user, token])

  return { isLogged }
}
