import { toast } from 'react-toastify'

export function showErrorToast(errorMessage: string) {
  toast.error(errorMessage, {
    theme: 'colored',
    draggable: false
  })
}

export function showSuccessToast(messageSuccess: string) {
  try {
    toast.success(messageSuccess, {
      theme: 'colored',
      draggable: false
    })
  } catch (error) {
    console.error(`Error removing standby purchase: ${error}`)
  }
}

export function showInfoToast(infoMessage: string) {
  toast.info(infoMessage, {
    theme: 'colored',
    draggable: false
  })
}

export function showAlertToast(alertMessage: string) {
  toast.warn(alertMessage, {
    theme: 'colored',
    draggable: false
  })
}
