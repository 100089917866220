import styled from 'styled-components'
import { themeColors } from '../../styles/theme.colors'
import { IStyleProps } from './types'

export const Overlay = styled.section<IStyleProps>`
  width: 100%;
  height: 100%;
  min-height: 100%;

  top: 0;
  left: 0;
  position: fixed;
  z-index: 200;

  background-color: ${(props) =>
    props.withBackground ? 'rgba(0, 0, 0, 0.4)' : 'transparent'};

  display: flex;
  align-items: center;
  justify-content: center;
`

export const ModalContainer = styled.section<IStyleProps>`
  width: 100%;
  position: fixed;
  z-index: 200;
  left: 0;
  bottom: 0;
  padding: 10px 16px;
  background-color: ${themeColors.white};
  max-height: ${(props) => (props.fillAllHeight ? '100vh' : '50vh')};
  display: flex;
  flex-direction: column;
`

export const ModalContent = styled.div<IStyleProps>`
  display: flex;
  flex-direction: ${(props) => (props.fillAllHeight ? 'row' : 'column')};
  overflow: auto;
`

export const ModalHeaderWrapper = styled.section`
  width: 100%;

  margin: 10px 0px 16px 0px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const CloseButton = styled.button`
  border: none;
  background-color: transparent;
`

export const TitleModal = styled.span`
  font-size: 2.4rem;
  color: ${themeColors.black};
`
