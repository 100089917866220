import React from 'react'

import { ISelectBrandComponentProps } from './types'

import * as S from './styles'

const SelectBrandComponent: React.FC<ISelectBrandComponentProps> = ({
  option,
  brandLogo,
  selectedBrand,
  handleChangeSelectBrand
}) => {
  return (
    <S.SelectBrandWrapper selected={option === selectedBrand} htmlFor={option}>
      {brandLogo.indexOf('http') > -1 ? (
        <img src={brandLogo} alt={option} />
      ) : (
        <span className="brandthumb" title={option}>
          {brandLogo}
        </span>
      )}

      <input
        type="radio"
        id={option}
        name={option}
        value={option}
        onChange={handleChangeSelectBrand}
        checked={option === selectedBrand}
      />
    </S.SelectBrandWrapper>
  )
}

export default SelectBrandComponent
