import React, { Fragment, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Container } from '../../layout'
import { IOrder } from '../../interfaces'
import { routesNameEnum } from '../../routes/routesNameEnum'
import { ButtonComponent, CustomerInfoLabel } from '../../components'
import { selectedCustomerContext } from '../../contexts/SelectedCustomer/selectedCustomer'

import { ReactComponent as ShareIcon } from '../../assets/images/share.svg'

import * as S from './styles'

const PurchaseCompleteTemplate: React.FC = () => {
  const navigate = useNavigate()

  const { state } = useLocation()
  const purchaseInformation = state as Partial<IOrder>

  const { customer, resetSelectedCustomer } = useContext(
    selectedCustomerContext
  )

  const handleSharePurchase = () => {
    if (navigator.share) {
      navigator.share({
        // TODO: switch to the correct information
        title: 'Nova compra no app Shooper Realizada',
        text: `Código do pedido: ${purchaseInformation.orderCode}`,
        url: purchaseInformation.linkForSharing
      })
    }
  }

  const handleOkay = () => {
    resetSelectedCustomer?.()
    navigate(routesNameEnum.HOME)
  }

  return (
    <Fragment>
      <CustomerInfoLabel
        label={`Cliente: ${customer?.email ? customer?.email : customer?.name}`}
      />
      <Container>
        <S.PurchaseCompleteTemplateWrapper>
          <S.ContentWrapper>
            <S.ScreenTitle>Compra completada!</S.ScreenTitle>
            <S.OrderCodeWrapper>
              <S.OrderCodeText>
                pedido {purchaseInformation.orderCode}
              </S.OrderCodeText>
            </S.OrderCodeWrapper>
            <S.ShareButton onClick={handleSharePurchase}>
              <ShareIcon />
              <S.ShareButtonText>Compartilhar</S.ShareButtonText>
            </S.ShareButton>
          </S.ContentWrapper>
          <S.ButtonConfirmWrapper>
            <ButtonComponent onClick={handleOkay}>OK</ButtonComponent>
          </S.ButtonConfirmWrapper>
        </S.PurchaseCompleteTemplateWrapper>
      </Container>
    </Fragment>
  )
}

export default PurchaseCompleteTemplate
