import { axiosInstance } from './api'

import { showErrorToast } from '../utils'
import {
  ICheckoutRequest,
  ICustomer,
  IPaginateData,
  IRegisterCustomer
} from '../interfaces'

export async function searchCustomer(
  authToken: string,
  document: string,
  page: number
): Promise<IPaginateData<ICustomer> | undefined> {
  try {
    if (document.length < 11) {
      return
    }
    const response = await axiosInstance.get(
      `/customers/search?document=${document}&page=${page}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    )
    return response.data
  } catch (err) {
    showErrorToast('Algo deu errado, tente novamente!')
  }
}

export async function registerCustomer(
  authToken: string,
  data: IRegisterCustomer
) {
  try {
    await axiosInstance.post('/shopper', data, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      }
    })
  } catch (error) {
    console.error(error)
    showErrorToast('Algo deu errado, tente novamente!')
  }
}

export async function finalizePurchase(
  authToken: string,
  requestBody: ICheckoutRequest
) {
  try {
    const response = await axiosInstance.post(
      '/shopper/finalizePurchase',
      requestBody,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      }
    )
    if (!response.data.success) return
    return response.data
  } catch (err) {
    console.error(err)
    showErrorToast(
      'Algo deu errado ao finalizar a compra. Por favor, tente novamente!'
    )
  }
}

export async function getPurchase(authToken: string, orderID: string) {
  try {
    const response = await axiosInstance.get(`/shopper?orderId=${orderID}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      }
    })
    return response.data
  } catch (err) {
    console.error(err)
    showErrorToast(
      'Algo deu errado ao buscar os detalhes da compra. Por favor, tente novamente!'
    )
  }
}
