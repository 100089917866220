export const themeColors = {
  white: '#FFF',
  dark_white: '#F9F9F9',
  black: '#000000',
  grey: '#9A9A9A',
  grey_3: '#828282',
  grey_5: '#E0E0E0',
  grey_input_color: '#676767',
  dark_grey: '#464646',
  light_grey: '#D9D9D9',
  primary_color: '#FB523A',
  secondary_color: '#FE2121',
  tertiary_color: '#FF0000',
  error_color: '#eb3452',
  link_color: '#878787',
  brand_bg_color: '#F3F3F3',
  toggle_offColor: '#FDA89C',
  beige_color: '#FFECE3',
  blue_color: '#92B5E8',
  green_color: '#C2FF85'
}
