import { useContext } from 'react'
import { Context } from './cart.context'

export const useCartContext = () => {
  const context = useContext(Context)
  if (typeof context === 'undefined') {
    throw new Error(
      'You have to use useCartContext inside <CartContextProvider />'
    )
  }
  return { ...context }
}
