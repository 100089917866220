import axios from 'axios'

/**
 * @description Please don't forget to comment after using the api locally. Always send the dev url to the repository
 */

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL_DEV || 'http://localhost:8080/api/v1'
})
export const externalAxios = axios.create({
  baseURL:
    process.env.REACT_APP_EXTERNAL_API ||
    'https://portaldaz.com.br/api/ws_shopper'
})

// export default axiosInstance
